import React, { useEffect } from 'react';
import { useResumeData } from '../components/Context/ResumeContext';  
import { Link,useNavigate } from 'react-router-dom';

const ChooseTemplate = () => {
  const { setResumeData } = useResumeData();
  

  const navigate = useNavigate();

  const handleTemplateSelect = (template) => {
    setResumeData((prevData) => ({
      ...prevData,
      selectedTemplate: template,
    }));

    navigate('/create-resume', { state: { scrollToTop: true } });
    window.scrollTo(0, 0);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const templates = [
    {
      id: 1,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-07%20172137_yHjgzrQkN.png?updatedAt=1723091002110',
      altText: 'Template1',
      link: '/create-resume',
    },
    {
      id: 2,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-07%20172226_wgnBt9SR2.png?updatedAt=1723091001231',
      altText: 'Template2',
      link: '/create-resume',
    },
    {
      id: 3,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-07%20172303_e6ZhjmdWg.png?updatedAt=1723091001197',
      altText: 'Template3',
      link: '/create-resume',
    },
    // {
    //   id: 4,
    //   imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-19%20111125_dXf2kpuAy.png?updatedAt=1724059007226',
    //   altText: 'Template4',
    //   link: '/create-resume',
    // },
    {
      id: 5,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-19%20144231_QpG3zJ7GEo.png?updatedAt=1724059007193',
      altText: 'Template5',
      link: '/create-resume',
    },
    {
      id: 6,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-19%20144257_-7VllQwpWk.png?updatedAt=1724059026752',
      altText: 'Template6',
      link: '/create-resume',
    },
    {
      id: 7,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-19%20144335_Vi7eKmmINe.png?updatedAt=1724059026630',
      altText: 'Template7',
      link: '/create-resume',
    },
    // {
    //   id: 8,
    //   imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-19%20144352_H99TlfP23.png?updatedAt=1724059007189',
    //   altText: 'Template8',
    //   link: '/create-resume',
    // },
    // {
    //   id: 9,
    //   imgSrc: 'https://ik.imagekit.io/microdegree/Resume%20Builder/Screenshot%202024-12-26%20103228_IhQ9bU1Bku.png?updatedAt=1735189640126',
    //   altText: 'Template9',
    //   link: '/create-resume2',
    // },
    // {
    //   id: 10,
    //   imgSrc: 'https://ik.imagekit.io/microdegree/Resume%20Builder/Screenshot%202024-12-26%20103228_IhQ9bU1Bku.png?updatedAt=1735189640126',
    //   altText: 'Template10',
    //   link: '/create-resume3',
    // }
  ];

  

  return (
    <div className="bg-[#f9f8fd]">
      <h1 className="pt-10 text-4xl">Please select a template for your resume</h1>
      <div style={styles.container}>
        {/* Check if templates is defined and has elements */}
        {Array.isArray(templates) && templates.length > 0 ? (
          templates.map((template) => (
            <Link
              to={template.link}
              key={template.id}
              onClick={() => handleTemplateSelect(template.altText)}
              style={styles.link}
            >
              <img
                src={template.imgSrc}
                alt={template.altText}
                style={styles.image}
              />
            </Link>
          ))
        ) : (
          <p>No templates available.</p> // Fallback for empty or undefined templates
        )}
      </div>
    </div>
  );
  
};

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '50px',
    padding: '20px',
    paddingTop: '60px',
  },
  link: {
    textDecoration: 'none',
    width: '100%',
    maxWidth: '400px',
  },
  image: {
    width: '100%', // Adjusts image to fit within the container width
    height: 'auto',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    transition: 'transform 0.2s',
    cursor: 'pointer',
  },
  '@media (max-width: 768px)': {
    container: {
      paddingTop: '40px',
    },
    image: {
      width: '90%', // Adjusts image to fit better on smaller screens
      maxWidth: '300px',
    },
  },
};

export default ChooseTemplate;
