import React from 'react';
import '../Template4/Resume4.css';
import { useResumeData } from '../Context/ResumeContext';
import { useEffect, useState } from 'react';

const RbResume4 = () => {
  const { resumeData } = useResumeData();
  const [photoUrl, setPhotoUrl] = useState(null);
  const {
    givenName = 'Sophia',
    familyName = 'Rivera',
    email = 'email@example.com',
    phone = '123-456-7890',
    address = '4758 Sunnydale Lane, Plains, Texas, United States, 79371',
    headline = 'Nursing Student',
    experiences = [],
    education = [],
    skills = [],
    technologies = [],
    languages = [],
    certifications = [],
    professionalSummary = '',
    experienceDescription = '',
    photo = null
  } = resumeData || {};

  useEffect(() => {
      // Clean up previous URL if it exists
      if (photoUrl) {
        URL.revokeObjectURL(photoUrl);
      }
  
      // Create new URL if photo exists and is a valid File/Blob
      if (photo instanceof Blob || photo instanceof File) {
        const newUrl = URL.createObjectURL(photo);
        setPhotoUrl(newUrl);
      } else if (typeof photo === 'string' && photo.startsWith('data:')) {
        // Handle base64 encoded images
        setPhotoUrl(photo);
      } else if (typeof photo === 'string' && (photo.startsWith('http://') || photo.startsWith('https://'))) {
        // Handle direct URLs
        setPhotoUrl(photo);
      }
  
      // Cleanup function
      return () => {
        if (photoUrl && !photoUrl.startsWith('data:') && !photoUrl.startsWith('http')) {
          URL.revokeObjectURL(photoUrl);
        }
      };
    }, [photo]);

  const handlePrint = () => {
    setTimeout(() => {
      window.print();
    }, 300);
  };

  return (
    <div className='mt-5'>
      <div className="rb-resume-container4">
        <header className="rb-header4">
          <div className="rb-header-text">
            <h1>{`${givenName} ${familyName}`}</h1>
            <p className='rb-text-left'>{headline}</p>
          </div>
          {photoUrl ? (
              <img 
                src={photoUrl}
                alt={`${givenName} ${familyName}`} 
                className="rb-profile-image4" 
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect width="100%" height="100%" fill="%23f0f0f0"/><text x="50%" y="50%" font-family="Arial" font-size="14" text-anchor="middle" dy=".3em" fill="%23666">No Photo</text></svg>';
                }}
              />
            ) : (
              <div className="rb-profile-image-placeholder4">No Photo</div>
            )}
        </header>
        <div className="rb-content-wrapper4 rb-text-left">
          <main className="rb-main-content4">
            <section className="rb-experience-section4">
              <h2>Professional Experience</h2>
              <p className='rb-text-sm'>{professionalSummary}</p>
            </section>
            <br />
            <section>
              <div className="rb-about-me-section">
                <h2>Work Experience</h2>
                <div className="rb-text-left rb-text-sm">
                  {experiences.map((exp, index) => {
                    const paragraphs = experienceDescription.split('\n\n');
                    const currentParagraph = paragraphs[index] || '';
                    const sentences = currentParagraph.split('. ');

                    return (
                      <div key={index}>
                        <p className='rb-font-semibold rb-text-lg rb-text-[#545b77] rb-color43'>{exp.company}</p>
                        <p className='rb-text-[#3D3D3D] rb-text-md rb-color1'>{exp.role}</p>
                        <p className='rb-text-[#7C7C7C] rb-text-sm rb-color2'>{`${exp.startDate} - ${exp.endDate}`}</p>
                        {sentences.map((sentence, sentenceIndex) => (
                          <p key={sentenceIndex}>
                            {sentenceIndex < sentences.length ? '•\u00A0' : ''}{sentence.trim()}
                            {sentenceIndex < sentences.length - 1 ? '.' : ''}
                          </p>
                        ))}
                        <br />
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
            <section className="rb-education-section4">
              <h2>Education</h2>
              {education.map((edu, index) => (
                <div key={index}>
                  <p className='rb-font-semibold rb-text-lg rb-text-[#545b77] rb-color43'>{edu.degree}</p>
                  <p className='rb-text-[#3D3D3D] rb-text-md rb-color1'>{edu.institution}</p>
                  <p className='rb-text-[#7C7C7C] rb-text-sm rb-color2'>{`${edu.startDate} - ${edu.endDate}`}</p>
                  <br />
                </div>
              ))}
            </section>
          </main>
          <aside className="rb-sidebar4">
            <section className="rb-contact-section4">
              <h2>Contact</h2>
              <p className='rb-text-sm'><i className="fa-regular fa-envelope mb-2"></i> &nbsp;{email}</p>
              <p className='rb-text-sm'><i className="fa-solid fa-phone mb-2"></i> &nbsp;{phone}</p>
              <p className='rb-text-sm'><i className="fa-solid fa-location-dot mb-2"></i> &nbsp;{address}</p>
            </section>
            <br />
            <h2>Skills</h2>
            <ul className="rb-skills-list rb-text-sm">
              {skills.map((skill, index) => (
                <li key={index} className="rb-skill-badge4">{skill}</li>
              ))}
            </ul>
            <br />
            <div className="rb-skills-section3 rb-text-left">
              <h2>Technologies</h2>
              <ul className="rb-skills-list2 rb-text-sm">
                {technologies.map((technology, index) => (
                  <li key={index} className="rb-skill-badge42">{technology}</li>
                ))}
              </ul>
            </div>
            <br />
            <div className="rb-language-section3 rb-text-left">
              <h2>Languages</h2>
              <ul className="rb-skills-list rb-text-sm">
                {languages.map((language, index) => (
                  <p key={index} className="rb-skill-badge4">{language}</p>
                ))}
              </ul>
            </div>
          </aside>
        </div>
      </div>

      <div className="rb-resume-containerX rb-flex justify-center items-center p-10">
        <button onClick={handlePrint} className="bg-blue-500 text-white px-4 py-2 rounded-md mr-4">
          Print
        </button>
      </div>
    </div>
  );
};

export default RbResume4;
