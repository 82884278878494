import React from 'react';
import '../Template7/Resume7.css';
import { useResumeData } from '../Context/ResumeContext';
import { useEffect, useState } from 'react';

const Resume7 = () => {
  const { resumeData } = useResumeData();
  const [photoUrl, setPhotoUrl] = useState(null);
  const {
    givenName = 'Olivia',
    familyName = 'Wilson',
    email = 'hello@oliviawilson.com',
    phone = '+123-456-7890',
    address = '123 Anywhere St., Any City',
    headline = 'Marketing Manager',
    summary = 'An experienced Marketing Manager with exceptional skills...',
    experiences = [],
    education = [],
    skills = [],
    languages = [],
    professionalSummary = '',
    experienceDescription = '',
    technologies = [],
    photo = null
  } = resumeData || {};

  useEffect(() => {
      // Clean up previous URL if it exists
      if (photoUrl) {
        URL.revokeObjectURL(photoUrl);
      }
  
      // Create new URL if photo exists and is a valid File/Blob
      if (photo instanceof Blob || photo instanceof File) {
        const newUrl = URL.createObjectURL(photo);
        setPhotoUrl(newUrl);
      } else if (typeof photo === 'string' && photo.startsWith('data:')) {
        // Handle base64 encoded images
        setPhotoUrl(photo);
      } else if (typeof photo === 'string' && (photo.startsWith('http://') || photo.startsWith('https://'))) {
        // Handle direct URLs
        setPhotoUrl(photo);
      }
  
      // Cleanup function
      return () => {
        if (photoUrl && !photoUrl.startsWith('data:') && !photoUrl.startsWith('http')) {
          URL.revokeObjectURL(photoUrl);
        }
      };
    }, [photo]);

  const handlePrint = () => {
    setTimeout(() => {
      window.print();
    }, 300);
  };

  return (
    <div className='mt-5'>
      <div className="rb-resume-container7">
        <div className="rb-sidebar7">
          <div className="rb-profile-section7 flex flex-col items-center">
          {photoUrl ? (
              <img 
                src={photoUrl}
                alt={`${givenName} ${familyName}`} 
                className="rb-profile-image7" 
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect width="100%" height="100%" fill="%23f0f0f0"/><text x="50%" y="50%" font-family="Arial" font-size="14" text-anchor="middle" dy=".3em" fill="%23666">No Photo</text></svg>';
                }}
              />
            ) : (
              <div className="rb-profile-image-placeholder7">No Photo</div>
            )}
            <h1>{`${givenName} ${familyName}`}</h1>
            <p>{headline}</p>
          </div>
          <div className="rb-contact-section7 text-left">
            <h3>Contact</h3>
            <p><i className="fa-regular fa-envelope mb-2"></i> &nbsp;{email}</p>
            <p><i className="fa-solid fa-phone mb-2"></i> &nbsp;{phone}</p>
            <p><i className="fa-solid fa-location-dot mb-2"></i> &nbsp;{address}</p>
          </div>
          <div className="rb-skills-section7 text-left">
            <h3>Skills</h3>
            <ul className="rb-skills-list7">
              {skills.map((skill, index) => (
                <li key={index} className="rb-skill-badge7">{skill}</li>
              ))}
            </ul>
          </div>
          <div className="rb-skills-section7 text-left">
            <h3>Technologies</h3>
            <ul className="rb-technologies-list7">
              {technologies.map((tech, index) => (
                <li key={index} className="rb-technologies-badge7">{tech}</li>
              ))}
            </ul>
          </div>
          <div className="rb-language-section7 text-left">
            <h3>Languages</h3>
            <ul className="rb-skills-list7">
              {languages.map((language, index) => (
                <p key={index} className="rb-skill-badge7">{language}</p>
              ))}
            </ul>
          </div>
        </div>
        <div className="rb-main-content7 text-left">
          <div className="rb-about-me-section7">
            <h3>PROFESSIONAL SUMMARY</h3>
            <p>{professionalSummary}</p>
          </div>
          <br />
          <div className="rb-about-me-section">
            <h3>WORK EXPERIENCE</h3>
            <div className="text-left">
              {experiences.map((exp, index) => {
                const paragraphs = experienceDescription.split('\n\n');
                const currentParagraph = paragraphs[index] || '';
                const sentences = currentParagraph.split('. ');

                return (
                  <div key={index}>
                    <p className="font-semibold text-lg text-[#7b1113]">{exp.company}</p>
                    <p className="text-[#3D3D3D] text-md rb-color1">{exp.role}</p>
                    <p className="text-[#7C7C7C] text-sm rb-color2">{`${exp.startDate} - ${exp.endDate}`}</p>
                    {sentences.map((sentence, sentenceIndex) => (
                      <p key={sentenceIndex}>
                        {sentenceIndex < sentences.length ? '•\u00A0' : ''}{sentence.trim()}
                        {sentenceIndex < sentences.length - 1 ? '.' : ''}
                      </p>
                    ))}
                    <br />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="rb-education-section7 text-left">
            <h3>EDUCATION</h3>
            {education.map((edu, index) => (
              <div key={index}>
                <p className="font-semibold text-lg text-[#7b1113]">{edu.degree}</p>
                <p className="text-[#3D3D3D] text-md rb-color1">{edu.institution}</p>
                <p className="text-[#7C7C7C] text-sm rb-color2">{`${edu.startDate} - ${edu.endDate}`}</p>
                <br />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="rb-resume-containerX text-center p-10">
        <button onClick={handlePrint} className="bg-blue-500 text-white px-4 py-2 rounded-md mr-4">
          Print
        </button>
      </div>
    </div>
  );
};

export default Resume7;
