import React, { useRef, useEffect, useState} from 'react';
import "../YTFullCoursePage.css";
import Enquiry from "../assets/Gifs/Enquiry.gif"

const YTFullCoursePage = () => {
  const videoRefs = useRef({});
  
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false); // State for scroll behavior

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
  };

    // Initial check and add resize listener
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    // Detect scroll and adjust sticky banner behavior
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    // Load the IFrame Player API asynchronously
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Assign the API ready function to the global window object
    window.onYouTubeIframeAPIReady = () => {
        Object.keys(videoRefs.current).forEach((id) => {
          const iframe = videoRefs.current[id];
          if (iframe) {
            videoRefs.current[id] = new window.YT.Player(iframe, {
              events: {
                onReady: onPlayerReady,
              },
            });
          }
        });
      };
  }, []);

  const onPlayerReady = (event) => {
    const player = event.target;
    videoRefs.current[player.getIframe().id] = player;
  };

  const togglePlayPause = (id) => {
    const player = videoRefs.current[id];
    
    if (player) {
      const playerState = player.getPlayerState();
      console.log(`Player State for ${id}:`, playerState); // Debugging
  
      if (playerState === window.YT.PlayerState.PLAYING) {
        player.pauseVideo();
      } else {
        player.playVideo();
      }
    } else {
      console.error(`Player not found for ID: ${id}`);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };
 

  const courseData = {
    cloudComputing: [
      { id: 1, videoUrl: "https://www.youtube.com/embed/sVlo7W3iDDQ", name: "DevOps - Basics to Advanced",
        description: "Learn how to speed up software development and operations with DevOps. Perfect for beginners and beyond!"
      },
      { id: 2, videoUrl: "https://www.youtube.com/embed/6ZGtOe---X8", name: "AWS - Basics to Advanced",
        description: "Discover how to use Amazon Web Services to build and manage apps in the cloud, from start to finish."
      },
      { id: 3, videoUrl: "https://www.youtube.com/embed/1ciWsHm-fS4", name: "Azure - Basics to Advanced",
        description: "Get started with Microsoft Azure and learn how to deploy and manage cloud apps easily"
      },
      { id: 4, videoUrl: "https://www.youtube.com/embed/vAy7rhoBa_E", name: "Docker - Basics to Advanced",
        description: "See how Docker makes apps easy to deploy and move, from basics to more advanced topics."
      },
      { id: 5, videoUrl: "https://www.youtube.com/embed/zSrVfT_W7PQ", name: "Kubernetes - Basics to Advanced",
        description: "Master Kubernetes and learn how to manage your app containers automatically."
      },
      { id: 6, videoUrl: "https://www.youtube.com/embed/fWXoRyzKjlY", name: "Terraform: Basics to Advanced",
        description: "Learn how Terraform automates and manages infrastructure easily."
      },
      { id: 7, videoUrl: "https://www.youtube.com/embed/eg39UDZpMPc", name: "Jenkins: Basics to Advanced",
        description: "See how Jenkins automates software builds and deployments effortlessly."
      },
    ],
    webDevelopment: [
      { id: 8, videoUrl: "https://www.youtube.com/embed/y6fzqBsi1-Y", name: "HTML and CSS - Basics to Advanced",
        description: "Create beautiful websites by learning HTML and CSS, from the basics to advanced designs."
      },
      { id: 9, videoUrl: "https://www.youtube.com/embed/ULzUzc0gMfI", name: "JavaScript - Basics to Advanced",
        description: "Make your web pages interactive with JavaScript, starting from the basics to advanced features."
      },
      { id: 10, videoUrl: "https://www.youtube.com/embed/-tzG0bjZV7A", name: "React JS - Basics to Advanced",
        description: "Build dynamic web apps with React, a powerful tool for creating interactive user interfaces."
      },
      { id: 11, videoUrl: "https://www.youtube.com/embed/PV4P3_gSgrE", name: "Node JS: Basics to Advanced",
        description: "Build fast, scalable apps with Node JS from start to finish."
      },
      { id: 12, videoUrl: "https://www.youtube.com/embed/l_zeLWFOqgA", name: "MongoDB: Basics to Advanced",
        description: "Discover how MongoDB handles data storage and retrieval smoothly."
      },
      { id: 13, videoUrl: "https://www.youtube.com/embed/nYU_ZBITFpo", name: "Python Full Stack: Basics to Advanced",
        description: "Learn to build web apps using Python for both front-end and back-end."
      },
    ],
    MERNStack: [
      { id: 14, videoUrl: "https://www.youtube.com/embed/o1GWE34J8ZY", name: "Part 1: MERN Stack HTML & CSS",
        description: "Create the structure and style of web pages using HTML & CSS."
      },
      { id: 15, videoUrl: "https://www.youtube.com/embed/57YHC5E3HMw", name: "Part 2: MERN Stack JavaScript",
        description: "Add dynamic interactivity to your MERN apps with JavaScript."
      },
      { id: 16, videoUrl: "https://www.youtube.com/embed/cUBvriog0og", name: "Part 3: MERN Stack To-Do App",
        description: "Build a functional To-Do App using the MERN stack, combining MongoDB, Express.js, ReactJS, and Node.js."
      },
      { id: 17, videoUrl: "https://www.youtube.com/embed/R8qiO-MB6lI", name: "Part 4: MERN Stack ReactJS",
        description: "Learn ReactJS in the MERN stack and create dynamic user interfaces with ease."
      },
    ],
    dataScience: [
      { id: 18, videoUrl: "https://www.youtube.com/embed/KpiSRshpIy0", name: "AI and ML - Basics to Advanced",
        description: "Explore the basics of AI and Machine Learning, and learn how to apply them to real-world problems."
      },
      { id: 19, videoUrl: "https://www.youtube.com/embed/AsghfLOROuY", name: "Data Analytics - Basics to Advanced",
        description: "Learn to analyze data effectively, starting from the basics and moving to advanced techniques."
      },
      { id: 20, videoUrl: "https://www.youtube.com/embed/Jlm-UUjWgcw", name: "Power BI - Basics to Advanced",
        description: "Learn Power BI basics and how to use it to create useful visualizations and reports for better decisions."
      },
      { id: 21, videoUrl: "https://www.youtube.com/embed/exqFMNJKDOE", name: "MySQL - Basics to Advanced",
        description: "Manage databases with MySQL, from beginner level to advanced database operations."
      },
    ],
    softwareTesting: [
      { id: 22, videoUrl: "https://www.youtube.com/embed/XI6AcMG4inw", name: "Manual Testing - Basics to Advanced",
        description: "Ensure software quality with manual testing, covering everything from the basics to advanced methods."
      },
      { id: 23, videoUrl: "https://www.youtube.com/embed/qqaYA6Erbb8", name: "Selenium Testing - Basics to Advanced",
        description: "Automate web testing with Selenium, from getting started to more advanced techniques."
      },
    ],
    programmingLanguages: [
      { id: 24, videoUrl: "https://www.youtube.com/embed/i5B9JPq3MbI", name: "Python - Basics to Advanced",
        description: "Start coding with Python, an easy-to-learn language that’s great for beginners and experts alike."
      },
      { id: 25, videoUrl: "https://www.youtube.com/embed/2w2ggu8vUc0", name: "Java - Basics to Advanced",
        description: "Learn Java programming, a popular language for building all kinds of applications."
      },
      { id: 26, videoUrl: "https://www.youtube.com/embed/jaodrLJle-M", name: "PHP: Basics to Advanced",
        description: "Learn how PHP powers dynamic websites, from simple scripts to advanced web applications"
      },
    ],
    systemadministration: [
      { id: 27, videoUrl: "https://www.youtube.com/embed/oB6koGfNSg8", name: "Linux: Basics to Advanced",
        description: "Explore Linux basics and advanced skills. Perfect for learning how to use and manage this OS."
      },
      { id: 28, videoUrl: "https://www.youtube.com/embed/q_VmwX8nGcg", name: "Networking - Basics to Advanced",
        description: "Understand how networks work, from basic concepts to more advanced networking skills."
      },
    ],
    others: [
      { id: 29, videoUrl: "https://www.youtube.com/embed/kBtq6XjCE7s", name: "Aptitude - Basics to Advanced",
        description: "Improve your problem-solving skills with this guide to mastering aptitude tests."
      },
    ],
  };


  const renderSection = (title, videos, isLastSection) => {
    return (
    <div className="section">
      <h2 className="section-title">{title}</h2>
      <div className="video-container">
        {videos.map((video) => (
          <div key={video.id} className="video-card">
            <iframe
  ref={(el) => {
    if (el) {
      videoRefs.current[`video-${video.id}`] = el;
    }
  }}
  id={`video-${video.id}`}
  title={`Video ${video.id}`}
  width="100%"
  height="220"
  src={video.videoUrl + "?enablejsapi=1"}
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
  loading="lazy"
/>
            <div className="video-content">
              <p className="video-name">{video.name}</p>
              <p className="video-description">{video.description}</p>
              <button 
                className="watch-button" 
                data-video-id={`video-${video.id}`}
                onClick={() => togglePlayPause(`video-${video.id}`)}
              >
                Watch
              </button>
            </div>
          </div>
          
        ))}
       
      </div>

      {/* Add the section divider line */}
      {/*
      {!isLastSection && (
  <div className="section-divider-line" style={{ marginTop: '30px', height: '2px', backgroundColor: 'black' }}></div>
)} */}

{!isLastSection && (
  <div className="section-divider-line"></div>
)}

        {/* Show sticky banner on mobile */}
        {isMobile && (
        <div
        className={`sticky-banner fixed left-0 w-full bg-indigo-600 text-white transition-all duration-300 ease-in-out ${
          isScrolled ? 'scrolled top-0 bottom-auto' : 'bottom-0'
        }`}
      >
          <div className="banner-content">
          <p>This is an enquiry field for our Free Course webpage.<br />
            Have questions? Contact us!</p>
            <button className="banner-button" onClick={() => window.open('https://mdegree.in/free_course_enq', '_blank')}>
            Enquire Now
            </button>
          </div>
          {/*<button className="close-banner" >&times;</button>*/}
        </div>
      )}
      <div className="enquiry-popup-wrapper">
      {/* Enquiry Button */}
      <button className="enquiry-toggle-button" onClick={togglePopup}>
        Enquire Now
      </button>
      {/* Show popup on desktop */}
      {!isMobile && showPopup && (
        <div className="popup-container">
          <div className="popup-content">
          <button className="close-btn" onClick={closePopup}>✖</button>
          <div className="popup-message">
            <img
                src={Enquiry} 
                alt="Enquiry Gif"
                className="popup-gif"
              />
            <p>Have any questions about our courses? Reach out to us! 👋</p>
            <button
                className="popup-enquiry-button"
                onClick={() => window.open('https://mdegree.in/free_course_enq', '_blank')}
              >
                Click Here to Enquire
              </button>
            </div>
          </div>
        </div>
      )}
      </div>

      
      {/*
      {showPopup && (
        <div className="popup-container">
          <div className="popup-content">
            <button className="close-btn" onClick={closePopup}>✖</button>
            <div className="popup-message">
              <img
                src={Enquiry} 
                alt="Enquiry Gif"
                className="popup-gif"
              />
              
              <p>Have any questions about our courses? Reach out to us! 👋</p>
              <button
                className="popup-enquiry-button"
                onClick={() => window.location.href = 'https://mdegree.in/free_course_enq'}
              >
                Click Here to Enquire
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    */}
    
    
        </div>
        
    );
  };
  

  return (
    <div>
      <h1 className="yt-page-title">Free Courses</h1>
      {renderSection("Cloud Computing", courseData.cloudComputing, false)}
      {renderSection("Web Development", courseData.webDevelopment, false)}
      {renderSection("MERN Stack", courseData.MERNStack, false)}
      {renderSection("Data Science", courseData.dataScience, false)}
      {renderSection("Software Testing & QA", courseData.softwareTesting, false)}
      {renderSection("Programming Languages", courseData.programmingLanguages, false)}
      {renderSection("System Administration", courseData.systemadministration, false)}
      {renderSection("Others", courseData.others, true)}
    </div>
  );
};

export default YTFullCoursePage;
