import React from 'react';
import './Resume.css';
import { useResumeData } from '../Context/ResumeContext'; 
import { useEffect, useState } from 'react';

const Resume = () => {
  const { resumeData } = useResumeData();
  const [photoUrl, setPhotoUrl] = useState(null);

  const {
    givenName = 'Olivia',
    familyName = 'Wilson',
    email = 'hello@oliviawilson.com',
    phone = '+123-456-7890',
    address = '123 Anywhere St., Any City',
    headline = 'Marketing Manager',
    experiences = [],
    education = [],
    skills = [],
    languages = [],
    professionalSummary = '',
    experienceDescription = '',
    technologies = [],
    photo = null
  } = resumeData || {};

  useEffect(() => {
    // Clean up previous URL if it exists
    if (photoUrl) {
      URL.revokeObjectURL(photoUrl);
    }

    // Create new URL if photo exists and is a valid File/Blob
    if (photo instanceof Blob || photo instanceof File) {
      const newUrl = URL.createObjectURL(photo);
      setPhotoUrl(newUrl);
    } else if (typeof photo === 'string' && photo.startsWith('data:')) {
      // Handle base64 encoded images
      setPhotoUrl(photo);
    } else if (typeof photo === 'string' && (photo.startsWith('http://') || photo.startsWith('https://'))) {
      // Handle direct URLs
      setPhotoUrl(photo);
    }

    // Cleanup function
    return () => {
      if (photoUrl && !photoUrl.startsWith('data:') && !photoUrl.startsWith('http')) {
        URL.revokeObjectURL(photoUrl);
      }
    };
  }, [photo]);

  const handlePrint = () => {
    setTimeout(() => {
      window.print();
    }, 300);
  };

  return (
    <div className='w-full sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto mt-5'>
      <div className="rb-resume-container">
        <div className="rb-sidebar">
          <div className="rb-profile-section flex flex-col items-center">
          {photoUrl ? (
              <img 
                src={photoUrl}
                alt={`${givenName} ${familyName}`} 
                className="rb-profile-image" 
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect width="100%" height="100%" fill="%23f0f0f0"/><text x="50%" y="50%" font-family="Arial" font-size="14" text-anchor="middle" dy=".3em" fill="%23666">No Photo</text></svg>';
                }}
              />
            ) : (
              <div className="rb-profile-image-placeholder">No Photo</div>
            )}
            <h1>{`${givenName} ${familyName}`}</h1>
            <h1>{headline}</h1>
          </div>
          <div className="rb-contact-section text-left">
            <h3>Contact</h3>
            <p><i className="fa-regular fa-envelope mb-2"></i> &nbsp;{email}</p>
            <p><i className="fa-solid fa-phone mb-2"></i> &nbsp;{phone}</p>
            <p><i className="fa-solid fa-location-dot mb-2"></i> &nbsp;{address}</p>
          </div>
          <div className="rb-skills-section text-left">
            <h3>Skills</h3>
            <ul className="rb-skills-list">
              {skills.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul>
          </div>
          <div className="rb-skills-section text-left">
            <h3>Technologies</h3>
            <ul className="rb-skills-list">
              {Array.isArray(technologies) && technologies.length > 0 ? (
                technologies.map((tech, index) => (
                  <li key={index} className="mb-1">{tech}</li>
                ))
              ) : (
                <li>No technologies listed</li>
              )}
            </ul>
          </div>
          <div className="rb-language-section text-left">
            <h3>Languages</h3>
            {languages.map((language, index) => (
              <p key={index}>{language}</p>
            ))}
          </div>
        </div>
        
        <div className="rb-main-content text-left">
          <div className="rb-about-me-section">
            <h3>PROFESSIONAL SUMMARY</h3>
            <p>{professionalSummary}</p>
          </div>
          <br/>
          <div className="rb-about-me-section">
            <h3>WORK EXPERIENCE</h3>
            <div className="text-left">
              {experiences.map((exp, index) => {
                const paragraphs = experienceDescription.split('\n\n');
                const currentParagraph = paragraphs[index] || '';
                const sentences = currentParagraph.split('. ');
                return (
                  <div key={index}>
                    <p className='font-semibold text-lg'>{exp.company}</p>
                    <p className='text-[#3D3D3D] text-md rb-color1'>{exp.role}</p>
                    <p className='text-[#7C7C7C] text-sm rb-color2'>{`${exp.startDate} - ${exp.endDate}`}</p>
                    {sentences.map((sentence, sentenceIndex) => (
                      <p key={sentenceIndex}>
                        {sentenceIndex < sentences.length ? '•\u00A0' : ''}{sentence.trim()}
                        {sentenceIndex < sentences.length - 1 ? '.' : ''}
                      </p>
                    ))}
                    <br/>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="rb-education-section text-left">
            <h3>EDUCATION</h3>
            {education.map((edu, index) => (
              <div key={index}>
                <p className='font-semibold text-lg'>{edu.degree}</p>
                <p className='text-[#3D3D3D] text-md rb-color1'>{edu.institution}</p>
                <p className='text-[#7C7C7C] text-sm rb-color2'>{`${edu.startDate} - ${edu.endDate}`}</p>
                <br/>
              </div>
            ))}
          </div>
        </div>
        <div className="rb-watermark">
           <span className="text-blue-500 !important">Micro</span>
           <span className="text-pink-500 !important">Degree</span>
       </div>
      </div>
     
      <div className="grid place-items-center text-center p-10">
        <button onClick={handlePrint} className="bg-blue-500 text-white px-4 py-2 rounded-md mr-4">
          Print
        </button>
      </div>
    </div>
  );
};

export default Resume;
