// src/contexts/ResumeContext.js
import React, { createContext, useContext, useState } from 'react';

const ResumeDataContext = createContext();

export const ResumeDataProvider = ({ children }) => {
    const [resumeData, setResumeData] = useState({
        // ... other initial state
        technologies: [] // Initialize technologies array
    });

    const updateTechnologies = (newTechnologies) => {
        setResumeData(prevData => ({
            ...prevData,
            technologies: Array.isArray(newTechnologies) ? newTechnologies : []
        }));
    };
      

    return (
        <ResumeDataContext.Provider value={{ 
            resumeData, 
            setResumeData,
            updateTechnologies 
        }}>
            {children}
        </ResumeDataContext.Provider>
    );
};

export const useResumeData = () => useContext(ResumeDataContext);