import React, { useState, useEffect } from 'react';

const LoadingPage = () => {

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false); // Hide the loading page after 5000ms
    }, 5000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="bg-white p-8 rounded-lg shadow-xl text-center">
        <div className="mx-auto mb-4 w-16 h-16 border-4 border-blue-500 border-t-4 border-t-transparent rounded-full animate-spin"></div>
        <h2 className="text-2xl font-bold mb-4">Generating Your Resume</h2>
        <p className="text-gray-600">Please wait while we create your personalized resume...</p>
      </div>
    </div>
  );
};

export default LoadingPage;