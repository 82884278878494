import React from 'react'
import logo from '../assets/navbar/MicroDegree Web.png'
import {SearchIcon, PhoneIcon, ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid'
import { FiAlignJustify } from "react-icons/fi";
import { Courses } from '../Courses'
import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react';
import Modal from './Modal';
import YTFullCoursePage from '../pages/YTFullCoursePage';


function Navbar({setSearchedResults}) {
  const [input, setInput] = useState('')
  const [dropDown1, setDropDown1] = useState(false)
  const [searchOutput, setSearchOutput] = useState([])
  const [viewSearchOutput, setViewSearchOutput] = useState(false)
  const [open, setOpen] = React.useState(false);

  const [viewModal, setViewModal] = useState(false);

  const handleConsultationClick = () => {
    setViewModal(true); // Open the modal
  };
 
  const handleClose = () => {
    setOpen(false);
 };

 const handleOpen = () => {
    setOpen(true);
 };


    const [dropDown, setDropDown] = useState(false);
    const dropdownRef = useRef(null);
  
    // Function to close the dropdown
    const closeDropdown = () => {
      setDropDown(false);
    };
  
    // Add event listener to detect clicks outside the dropdown
    useEffect(() => {
      function handleClickOutside(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          closeDropdown();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      // Clean up the event listener
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropdownRef]);

    const toggleDropdown = () => {
      setDropDown(prevState => !prevState);
    };

  const keys = ["course_name","course_category"]

  useEffect(() => {
    const sidebar = document.getElementById('sidebar');
    const openSidebarButton = document.getElementById('open-sidebar');
    
    const handleOpenSidebar = (e) => {
      e.stopPropagation();
      sidebar.classList.toggle('-translate-x-full');
    };

    const handleCloseSidebar = (e) => {
      if (!sidebar.contains(e.target) && !openSidebarButton.contains(e.target)) {
        sidebar.classList.add('-translate-x-full');
      }
    };

    openSidebarButton.addEventListener('click', handleOpenSidebar);
    document.addEventListener('click', handleCloseSidebar);

    // Cleanup event listeners on component unmount
    return () => {
      openSidebarButton.removeEventListener('click', handleOpenSidebar);
      document.removeEventListener('click', handleCloseSidebar);
    };
  }, []);

  // Add state for managing dropdown
  const [isToolsOpen, setIsToolsOpen] = useState(false);

  // Add useEffect for handling clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.tools-dropdown')) {
        setIsToolsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div>
    <div className='flex max-h-[70px] h-full w-full p-6 2xl:max-h-[90px] 2xl:py-7 px-7 xl:px-6.5 relative justify-between items-center '>
    
     
      <div className='flex-shrink flex items-center 2xl:justify-center  md:ml-6 2xl:ml-20 '>
   
        <a href='https://www.microdegree.work/'><img className="min-w-[130px] max-w-[130px] md:min-w-[140px] md:max-w-[140px] 2xl:min-w-[200px] 2xl:max-w-[200px] " src={logo} alt="" loading="lazy"/></a>
      </div>
      <div className=' flex basis-1   md:basis-7/12 sm:basis-9/12 justify-start items-center'>
        {/* {<div className=' hidden max-h-[40px]  sm:block basis-5/12 flex-shrink mr-3 relative '>
      <form className="flex h-full items-center  bg-white p-[0.4rem] py-2 2xl:py-1 2xl:h-12 w-60  rounded-md">
            <input className='flex h-full w-full items-center justify-center text-sm 2xl:text-lg px-2 xl:placeholder:text-xs placeholder:text-sm 2xl:placeholder:px-5  outline-none' onChange={handleInput }  placeholder='What do you want to learn?' type='text'></input>
            <SearchIcon className='w-7 2xl:w-9 2xl:p-2 text-white p-1 rounded-md bg-[#FF2A67]'></SearchIcon>
          </form>
          <div className={` ${viewSearchOutput?'block':"hidden"} border-t-2 w-full absolute z-10`}>
            {searchOutput.map(item=>(
              <h1 onClick={handleSearched} key={item.id} className=' hover:text-[#FF2A67] cursor-pointer w-full bg-white text-sm 2xl:text-base py-2 text-left px-2  text-gray-700'>{item.course_name}</h1>
              ))}
          </div>
          </div>} */}
          <ul className="flex relative 2xl:text-lg w-fullmd:basis-10/12 ml-10 mr-10 sm:ml-8 justify-around items-center space-x-2 xl:space-x-0">
          {/* <a href="https://www.microdegree.work/Awsdevops" target="_blank" rel="noopener noreferrer" ><li className='2xl:flex xl:flex hidden md:hidden hover:bg-[#FF2A67]  justify-center w-44 xl:w-21 hover:text-white px-2 xl:px-0 py-1 rounded-full '> <p>AWS & DevOps</p></li></a> */}
          {/* <a href="https://www.microdegree.work/pythonfullstack" target="_blank" rel="noopener noreferrer" ><li className='2xl:flex xl:flex md:hidden hover:bg-[#FF2A67] sm:flex justify-center w-44 2xl:w-44 xl:w-40 hidden md:block hover:text-white px-2  py-1 rounded-full '> <p>Python Full-Stack</p></li></a> */}
          
          {/*<a href="https://trainings.microdegree.work/yt-full-course" target="_blank" rel="noopener noreferrer" ><li className='2xl:flex xl:flex md:hidden hover:bg-[#FF2A67] sm:flex justify-center w-44 hidden md:block  hover:text-white px-2 py-1 rounded-full '> <p>Free Courses</p></li></a>  */}
          {/*<a href="https://microdegree.work/YTFullCoursePage" target="_blank" rel="noopener noreferrer" ><li className='2xl:flex xl:flex md:hidden hover:bg-[#FF2A67] sm:flex justify-center w-44 hidden md:block  hover:text-white px-2 py-1 rounded-full '> <p>Free Courses</p></li></a>*/}
          <a href="#section2"  style={{color:"black"}}><li className='2xl:flex xl:flex md:hidden hover:bg-[#FF2A67] sm:flex justify-center w-50 mx-3 2xl:w-30 xl:w-36 hidden md:block hover:text-white px-1 py-1 rounded-full'> <p>Free Demo</p></li></a>
              <div className=' sm:relative sm:w-full h-full w-fit sm:px-2'>
              <div className='hidden sm:block md:block'>
              <div className='2xl:flex xl:flex md:hidden flex items-center justify-center ' onClick={()=>setDropDown(!dropDown)}>
            <li className='cursor-pointer hover:border-bottom select-none px-2 xl:px-0 py-1 hover:text-[#FF2A67] rounded-full'>Courses</li>
            <ChevronDownIcon className={`w-4 cursor-pointer ${dropDown?'hidden':'block'}`}></ChevronDownIcon>
            <ChevronUpIcon className={`w-4 cursor-pointer ${dropDown?'block':'hidden'}`}></ChevronUpIcon>
            </div>
              </div>
        
            <div className='hidden md:hidden sm:block xl:hidden lg:hidden md:block flex items-center justify-center ' onClick={()=>setDropDown(!dropDown)}>
            <li className='ml-12 cursor-pointer hover:border-bottom select-none xl:px-10 py-1 hover:text-[#FF2A67] rounded-full'>Courses</li>
            <ChevronDownIcon className={`w-4 cursor-pointer ${dropDown?'hidden':'block'}`}></ChevronDownIcon>
            <ChevronUpIcon className={`w-4 cursor-pointer ${dropDown?'block':'hidden'}`}></ChevronUpIcon>
            </div>
            <div className={`absolute left-10 top-16 z-10 sm:top-10 sm:left-0 w-fit select-none bg-white py-3 ${dropDown ? 'block' : 'hidden'} whitespace-nowrap transition-all ease-linear duration-700`} ref={dropdownRef}>
      {Courses.map(item => (
        <a href={item.course_link} target="_blank" rel="noopener noreferrer" key={item.id}><p className='hover:bg-[#FF2A67] hover:text-white text-left w-full bg-white text-sm 2xl:text-base py-2 px-5 text-gray-700'>{item.course_name}</p></a>
      ))}
    </div>
            </div>
             {/* <a className='hidden flex justify-end items-start' href="tel:+918047109999"> <PhoneIcon className='flex justify-start w-6 bg-[#FF2A67] px-1 text-white rounded-full p-[0.3rem]'></PhoneIcon> </a>
             <button class="hidden items-right justify-end text-gray-500 hover:text-gray-600" id="open-sidebar">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button> */}

             {/*
             <a href="https://trainings.microdegree.work/yt-full-course" target="_blank" rel="noopener noreferrer" style={{color:"black"}}><li className='2xl:flex xl:flex md:hidden hover:bg-[#FF2A67] sm:flex justify-center w-36 hidden md:block  hover:text-white px-1 py-1 rounded-full'> <p>Free Courses</p></li></a>
             */}
             <a href="https://play.google.com/store/apps/details?id=work.microdegree.trainings&referrer=utm_source=webpage&utm_medium=header&utm_campaign=app_launch" target="_blank" rel="noopener noreferrer" style={{color:"black"}}><li className='2xl:flex xl:flex md:hidden hover:bg-[#FF2A67] sm:flex justify-center w-50 mx-3 2xl:w-30 xl:w-36 hidden md:block hover:text-white px-1 py-1 rounded-full'> <p>Mobile App</p></li></a>
            

             <div className="hidden sm:block md:block relative tools-dropdown">
  <div 
    className="flex items-center gap-1 cursor-pointer hover:text-[#FF2A67] pr-4" 
    onClick={(e) => {
      e.stopPropagation();
      setIsToolsOpen(!isToolsOpen);
    }}
  >
    <p>Tools</p>
    <svg
      className={`w-4 h-4 transition-transform duration-200 ${isToolsOpen ? 'rotate-180' : ''}`}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 9l-7 7-7-7"
      />
    </svg>
  </div>
  {isToolsOpen && (
    <div className="absolute left-0 mt-2 w-80 bg-white rounded-lg shadow-lg py-2 z-50">
      <a href="/YTFullCoursePage" target="_blank" rel="noopener noreferrer">
        <p className='hover:bg-[#FF2A67] hover:text-white text-left w-full bg-white text-sm 2xl:text-base py-2 px-5 text-gray-700'>
          Free Courses
        </p>
      </a>
      <a href="https://www.microdegree.work/company_vacancies" target="_blank" rel="noopener noreferrer">
        <p className='hover:bg-[#FF2A67] hover:text-white text-left w-full bg-white text-sm 2xl:text-base py-2 px-5 text-gray-700'>
          DevOps Jobs
        </p>
      </a>
      <a href="/it-salary-growth-calculator" target="_blank" rel="noopener noreferrer">
        <p className='hover:bg-[#FF2A67] hover:text-white text-left w-full bg-white text-sm 2xl:text-base py-2 px-5 text-gray-700'>
          IT Salary Growth Calculator
        </p>
      </a>
      <a href="/interviewquestions" target="_blank" rel="noopener noreferrer">
        <p className='hover:bg-[#FF2A67] hover:text-white text-left w-full bg-white text-sm 2xl:text-base py-2 px-5 text-gray-700'>
          Cloud & DevOps Interview Questions
        </p>
      </a>
      <a href="/microresume" target="_blank" rel="noopener noreferrer">
        <p className='hover:bg-[#FF2A67] hover:text-white text-left w-full bg-white text-sm 2xl:text-base py-2 px-5 text-gray-700'>
          Resume Builder
        </p>
      </a>
    </div>
  )}
</div>

             {/* <a href="/YTFullCoursePage" target="_blank" rel="noopener noreferrer" style={{color:"black"}}><li className='2xl:flex xl:flex md:hidden hover:bg-[#FF2A67] sm:flex justify-center w-36 2xl:w-26 xl:w-32 hidden md:block  hover:text-white px-1 py-1 rounded-full'> <p>Free Courses</p></li></a>
             
             <a href="https://www.microdegree.work/company_vacancies" target="_blank" rel="noopener noreferrer" style={{color:"black"}}><li className='2xl:flex xl:flex md:hidden hover:bg-[#FF2A67] sm:flex justify-center w-36 2xl:w-36 xl:w-32 hidden md:block hover:text-white px-1 py-1 rounded-full'> <p>DevOps Jobs</p></li></a> */}
             <button onClick={()=>setViewModal('77')}><li className='2xl:flex md:hidden hover:bg-[#FF2A67] sm:flex xl:flex justify-center w-52 xl:w-48 hidden  hover:text-white px-2 xl:px-1 py-1 rounded-full '> <PhoneIcon className='w-4 mr-2'></PhoneIcon> <p>0804-710-9999</p></li></button>
              {/* <a className="hidden sm:block" href="https://www.microdegree.work/webinar"><li className=' hover:bg-[#FF2A67] sm:flex justify-center w-15 hidden  hover:text-white px-2 py-1 rounded-full font-medium'>Webinar</li></a> */}
              {/* <a href="https://www.microdegree.work/webinar" target="_blank" rel="noopener noreferrer" style={{color:"black"}}><li className='2xl:flex xl:flex md:hidden hover:bg-[#FF2A67] sm:flex justify-center w-15 hidden md:block  hover:text-white  xl:px-4 py-1 rounded-full '> <p>Webinar</p></li></a> */}
              <a href="https://courses.microdegree.work/users/sign_in" target="_blank" rel="noopener noreferrer" style={{color:"black"}}><li className='sm:hidden hidden 2xl:flex xl:flex md:hidden hover:bg-[#FF2A67] justify-center w-15 xl:w-13 hidden md:block hover:text-white px-4 xl:px-4 py-1 rounded-full '> <p>Login</p></li></a>
              
              <div
              className='flex sm:hidden space-x-6 2xl:hidden xl:hidden md:flex '>
                <a href="https://courses.microdegree.work/users/sign_in" target="_blank" rel="noopener noreferrer" class="font-poppins tracking-tight font-medium text-lg text-left block hover:text-[#FF2A67] text-[#4159ec] ">Login</a>
                <button class="sm:hidden md:flex  items-right justify-end  text-gray-500 hover:text-gray-600" id="open-sidebar">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button>
            </div>
            </ul>
            {viewModal && <Modal setViewModal={setViewModal} viewModal={viewModal} />}
      </div>
    </div>
    

            <section style={{backgroundColor:"#FF0049",textAlign:"center"}} >
   <div className="hidden sm:block text-center">
   <a  href="https://www.microdegree.work/webinar" target="_blank" rel="noopener noreferrer"><h6 className='text-2xl flex justify-center items-center' style={{color:"#fff",fontSize:"14px"}}>Join our Free Webinar at 9 PM 🚀 - Unlock New Opportunities Today! |&nbsp;&nbsp;<span><u><a href="https://www.microdegree.work/webinar" target="_blank" rel="noopener noreferrer">Register Now <i class="fa fa-angle-double-right"></i></a></u></span></h6></a>
   </div>
   <div className="block sm:hidden">
   <a  href="https://www.microdegree.work/webinar" target="_blank" rel="noopener noreferrer"><h6 className='text-2xl flex justify-center items-center' style={{color:"#fff",fontSize:"12px"}}>Join our Free Webinar at 9 PM 🚀|&nbsp;&nbsp;<span><u><a href="https://www.microdegree.work/webinar" target="_blank" rel="noopener noreferrer">Register Now <i class="fa fa-angle-double-right"></i></a></u></span></h6></a>
  </div>
  </section>
  <div class="absolute z-30 bg-white text-gray-500 w-56 min-h-screen overflow-y-auto transition-transform transform -translate-x-full ease-in-out duration-300"
        id="sidebar">
        <div class="p-4">
            <ul class="mt-4">
            <li class="mb-2"><a href="#section2" class="font-poppins tracking-tight font-medium text-lg text-left block hover:text-[#FF2A67]">Free Demo</a></li>
                <li class="mb-2"><a href="https://www.microdegree.work/Awsdevops" target="_blank" rel="noopener noreferrer" class="font-poppins tracking-tight font-medium text-lg text-left block hover:text-[#FF2A67]">AWS & DevOps</a></li>
                {/* <li class="mb-2"><a href="https://www.microdegree.work/pythonfullstack" target="_blank" rel="noopener noreferrer" class="font-poppins tracking-tight text-left text-lg font-medium block hover:text-[#FF2A67]">Python Full-Stack</a></li> */}
                {/* <li class="mb-2"><a href="#section1" target="_blank" rel="noopener noreferrer" class="font-poppins tracking-tight text-left text-lg font-medium block hover:text-[#FF2A67]">All Courses</a></li> */}
                {/*<li class="mb-2"><a href="https://trainings.microdegree.work/yt-full-course" target="_blank" rel="noopener noreferrer" class="font-poppins tracking-tight text-left text-lg font-medium block hover:text-[#FF2A67]">Free Courses</a></li>
                */}
                <li class="mb-2"><a href="https://play.google.com/store/apps/details?id=work.microdegree.trainings&referrer=utm_source=webpage&utm_medium=header&utm_campaign=app_launch" target="_blank" rel="noopener noreferrer" class="font-poppins tracking-tight text-left text-lg font-medium block hover:text-[#FF2A67]">Free Mobile App</a></li>
                <li class="mb-2"><a href="/YTFullCoursePage" target="_blank" rel="noopener noreferrer" class="font-poppins tracking-tight text-left text-lg font-medium block hover:text-[#FF2A67]">Free Courses</a></li>
                
                <li class="mb-2"><a href="https://www.microdegree.work/company_vacancies"  target="_blank" rel="noopener noreferrer" class="font-poppins tracking-tight text-left text-lg font-medium block hover:text-[#FF2A67]">DevOps Jobs</a></li>
                <li class="mb-2"><a href="https://www.microdegree.work/webinar" target="_blank" rel="noopener noreferrer" class="font-poppins tracking-tight text-left text-lg font-medium block hover:text-[#FF2A67]">Webinar</a></li>
                <li class="mb-2"><a href="/it-salary-growth-calculator"  target="_blank" rel="noopener noreferrer" class="font-poppins tracking-tight text-left text-lg font-medium block hover:text-[#FF2A67]">IT Salary Growth Calculator</a></li>
                <li class="mb-2"><a href="/interviewquestions"  target="_blank" rel="noopener noreferrer" class="font-poppins tracking-tight text-left text-lg font-medium block hover:text-[#FF2A67]">Cloud & DevOps Interview Questions
                </a></li>
                <li class="mb-2"><a href="https://courses.microdegree.work/users/sign_in" target="_blank" rel="noopener noreferrer" class="font-poppins tracking-tight font-medium text-lg text-left block hover:text-[#FF2A67] text-[#4159ec]">Login</a></li>
            </ul>
        </div>
    </div>
    </div>
  )
}


export default Navbar