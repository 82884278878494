import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import { useResumeData } from './components/Context/ResumeContext';
import { useNavigate,useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import LoadingPage from './components/LoadingPage';

const FormComponent = () => {

  const { setResumeData } = useResumeData();
  const { resumeData } = useResumeData();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Scroll to top when component mounts and if coming from template selection
    if (location.state?.scrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // This adds a smooth scrolling effect
      });
    }
  }, [location]);

  const [formData, setFormData] = useState({
    givenName: '',
    familyName: '',
    email: '',
    headline: '',
    phone: '',
    address: '',
    postCode: '',
    city: '',
    summary: '',
    experiences: [{
      company: '',
      role: '',
      startDate: '',
      endDate: '',
      description: ''
    }],
    education: [{
      institution: '',
      degree: '',
      startDate: '',
      endDate: '',
      description: ''
    }],
    internships: [{
      company: '',
      role: '',
      startDate: '',
      endDate: '',
      description: ''
    }],
  projects: [{
      title: '',
      description: ''
    }],
    skills: [''],
    languages: [''],
    technologies: [''],
    photo: null,
  });

  const [formErrors, setFormErrors] = useState({});
  
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [formattedResume, setFormattedResume] = useState(null);
  const [summary, setSummary] = useState(''); 


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    if (name === 'summary') {
      setSummary(value); // Update the summary variable when summary input changes
    }
  };

  

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      photo: e.target.files[0]
    });
  };

  const handleArrayChange = (index, field, value, section) => {
    const updatedSection = formData[section].map((item, i) => (
      i === index ? { ...item, [field]: value } : item
    ));
    setFormData({
      ...formData,
      [section]: updatedSection
    });
  };

  // const handleAddEntry = (arrayName) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [arrayName]: [...prevData[arrayName], { company: '', role: '', startDate: '', endDate: '' }],
  //   }));
  // };

  const handleAddSkill = () => {
    setFormData((prevData) => ({
      ...prevData,
      skills: [...prevData.skills, ''],
    }));
  };

 
  const handleSkillChange = (index, value) => {
    const updatedSkills = formData.skills.map((skill, i) => (
      i === index ? value : skill
    ));
    setFormData({
      ...formData,
      skills: updatedSkills
    });
  };


  const handleAddTechnology = () => {
    setFormData((prevData) => ({
      ...prevData,
      technologies: [...prevData.technologies, ''],
    }));
  };

  const handleTechnologyChange = async (index, value) => {
    const updatedTechnologies = formData.technologies.map((tech, i) => (
      i === index ? value : tech
    ));
    setFormData({
      ...formData,
      technologies: updatedTechnologies
    });
  };

  const handleAddLanguage = () => {
    setFormData((prevData) => ({
      ...prevData,
      languages: [...prevData.languages, ''],
    }));
  };

  const handleLanguageChange = (index, value) => {
    const updatedLanguages = formData.languages.map((lang, i) => (
      i === index ? value : lang
    ));
    setFormData({
      ...formData,
      languages: updatedLanguages
    });
  };

  const handleAddEntry = (arrayName) => {
    setFormData((prevData) => ({
      ...prevData,
      [arrayName]: [
        ...prevData[arrayName], 
        { company: '', role: '', startDate: '', endDate: '', description: '' }
      ],
    }));
  };

  

  const handleGenerateResume = () => {
    if (resumeData.selectedTemplate) {
      setIsLoading(true);
      setTimeout(() => {
        navigate(`/resume/${resumeData.selectedTemplate}`);
        setIsLoading(false);
      }, 5000);
    } else {
      alert('Please select a template first');
    }
  };

  const validate = () => {
    const errors = {};
  
    if (!formData.givenName) errors.givenName = 'Given name is required';
    if (!formData.familyName) errors.familyName = 'Family name is required';
    // if (!formData.photo) errors.photo = 'Photo is required';
    if (!formData.email) errors.email = 'Email address is required';
    if (!formData.headline) errors.headline = 'Headline is required';
    if (!formData.phone) errors.phone = 'Phone number is required';
    // if (!formData.address) errors.address = 'Address is required';
    // if (!formData.postCode) errors.postCode = 'Post code is required';
    // if (!formData.city) errors.city = 'City is required';


   // Validate experiences
   formData.experiences.forEach((experience, index) => {
    if (!experience.company) {
      errors.company = 'Company is required';
    }
    if (!experience.role) {
      errors.role = 'Role is required';
    }
    if (!experience.startDate) {
      errors.startDate= 'Start Date is required';
    }
    if (!experience.endDate) {
      errors.endDate = 'End Date is required';
    }
    if (!experience.description) {
      errors.description = 'Description is required';
    }
  });

  // Validate internships
  formData.internships.forEach((internship, index) => {
    if (!internship.company) {
      errors.internshipCompany = 'Company is required';
    }
    if (!internship.role) {
      errors.internshipRole = 'Role is required';
    }
    if (!internship.startDate) {
      errors.internshipStartDate = 'Start Date is required';
    }
    if (!internship.endDate) {
      errors.internshipEndDate = 'End Date is required';
    }
    if (!internship.description) {
      errors.internshipDescription = 'Description is required';
    }
  });

  // Validate internships
  formData.projects.forEach((project, index) => {
    if (!project.title) {
      errors.projecttitle = 'Title is required';
    } 
    if (!project.description) {
      errors.projectDescription = 'Description is required';
    }
  });

  // Validate education
  formData.education.forEach((edu, index) => {
    if (!edu.institution) {
      errors.institution = 'Institution is required';
    }
    if (!edu.degree) {
      errors.degree = 'Degree is required';
    }
    if (!edu.startDate) {
      errors.startDate = 'Start Date is required';
    }
    if (!edu.endDate) {
      errors.endDate = 'End Date is required';
    }
  });
  
  // Initialize errors.skills as an array if not already
  // errors.skills = [];

  // formData.skills.forEach((skill, index) => {
  //   if (!skill) {
  //     errors.skills = 'Skill is required';  // Assign error to specific index
  //   }
  // });

  // Clean up the skills error array if it's empty
  // if (errors.skills.length === 0) {
  //   delete errors.skills;
  // }

  // Initialize errors.skills as an array if not already
  errors.technologies = [];

  formData.technologies.forEach((technology, index) => {
    if (!technology) {
      errors.technologies = 'Technology is required';  // Assign error to specific index
    }
  });

  // Clean up the skills error array if it's empty
  if (errors.technologies.length === 0) {
    delete errors.technologies;
  }

  // Initialize errors.skills as an array if not already
  errors.languages = [];

  formData.languages.forEach((language, index) => {
    if (!language) {
      errors.languages = 'Language is required';  // Assign error to specific index
    }
  });

  // Clean up the skills error array if it's empty
  if (errors.languages.length === 0) {
    delete errors.languages;
  }
  
    // if (!formData.languages) errors.languages = 'Language is required';
    // if (!formData.technologies) errors.technologies = 'Technologies is required';
  
    return errors;
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResumeData(formData); 

  const errors = validate();
  
  // Check if there are any validation errors
  if (Object.keys(errors).length > 0) {
    setFormErrors(errors); // Set the errors to display them in the form
    return; // Stop the form submission if there are errors
  }
  
  const technologiesList = formData.technologies.filter(tech => tech.trim()).join(", ");

  const internshipDetails = formData.internships.map((intern, index) => `
    
    - Role: ${intern.role || 'Not provided'}
    - Company: ${intern.company || 'Not provided'}
    - Period: ${intern.startDate || 'Not provided'} - ${intern.endDate || 'Not provided'}
    - Description: ${intern.description.replace(/\. /g, '.\n    ') || 'Not provided'}
    `).join('\n');

    const projectDetails = formData.projects.map((proj, index) => `
    
    - Title: ${proj.role || 'Not provided'}
    - Description: ${proj.description.replace(/\. /g, '.\n    ') || 'Not provided'}
    `).join('\n');
   
    const promptContent = `
    Generate a polished and comprehensive Professional Summary and Experience based on the following details. Improve, expand, and elaborate on the information where possible. separate each section appropriately.Maximum 2 lines. Here are the details to include:

    
    Professional Summary:
    ${formData.summary || 'Not provided'}
    Generate a professional summary for a ${formData.headline || 'Not provided'} role. Please ensure to enhance the provided details to make them more detailed, impactful, and professional.For professional summary generate Maximum 30 words.

    Internships:
    ${internshipDetails}
    Please enhance the internship details to highlight key learnings, achievements, and responsibilities. For internships generate Maximum 4 points in a paragraph. Generate internship descriptions in paragraph format. If there are multiple internships generate description in separate paragraphs for respective internships.
    
    Projects:
    ${projectDetails}
    Please enhance the project details to highlight key learnings, achievements, and responsibilities. For internships generate Maximum 4 points in a paragraph. Generate internship descriptions in paragraph format. If there are multiple projects generate description in separate paragraphs for respective projects.

    Experience:
  ${formData.experiences.length > 0 ? formData.experiences.map(exp => `
    - Description: ${exp.description.replace(/\. /g, '.\n    ') || 'Not provided'}
`).join('\n') : 'Not provided'}
     
    Please ensure to enhance the provided details to make them more detailed, impactful, and professional.For experience generate Maximum 4 points in a paragraph. Generate experience in paragraph. If there are 2 experiences generate description in 2 paragraphs for respective experiences.


    Technology Suggestions:
    Based on these technologies: ${technologiesList}
    Please suggest 5 additional relevant technologies that would complement this stack. Format as "Additional Technologies: tech1, tech2, tech3, tech4, tech5"

    `;

    setIsLoading(true);
    window.scrollTo(0, 0);
    
    try {
      const res = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: "gpt-3.5-turbo",
        messages: [{ role: "user", content: promptContent }],
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer sk-proj-wBzdFWyuRVVQIHEgSyGhT3BlbkFJSD2Zn5NAWBVguZ0Mu6IO`
        }
      });

      
  
      const generatedContent = res.data.choices[0].message.content;
      setResponse(generatedContent);
      setFormattedResume(parseResponse(generatedContent));
      setIsLoading(false);

      const techMatch = generatedContent.match(/Additional Technologies: (.*?)(?:\n|$)/);
      if (techMatch && techMatch[1]) {
        const suggestedTechs = techMatch[1].split(',').map(tech => tech.trim());
        
        // Log the match and extracted technologies
        console.log('Tech Match:', techMatch);
        console.log('Extracted Technologies:', suggestedTechs);

        
        setFormData(prevData => {
          // Now we can log prevData since we're inside the callback
          console.log('Previous Technologies:', prevData.technologies);
          const newTechnologies = [...prevData.technologies, ...suggestedTechs];
          console.log('Updated Technologies:', newTechnologies);
          return {
            ...prevData,
            technologies: newTechnologies
          };
        });
      }

      
      
      const professionalSummary = extractProfessionalSummary(generatedContent);
      const experienceDescription = extractExperienceDescriptions(generatedContent);
      const internshipDescription = extractInternshipDescriptions(generatedContent);
      const projectDescription = extractProjectDescriptions(generatedContent);
  
      console.log('Generated Professional Summary:');
      console.log(professionalSummary);
      console.log('\nGenerated Experience:');
      console.log(experienceDescription);
      console.log('Extracted Internship Descriptions:', internshipDescription);
      console.log('Extracted Project Descriptions:', projectDescription);
  
    setResumeData(prevData => ({ ...prevData, professionalSummary,experienceDescription,internshipDescription,projectDescription })); 

    } catch (error) {
      console.error("There was an error with the API request", error);
      setResponse("There was an error with the API request");
      setIsLoading(false);
    }
  };


  const extractProfessionalSummary = (text) => {
    // This regex looks for "Professional Summary:" followed by any text until two newlines or the end of the text
    const summarySections = text.match(/Professional Summary:\n([\s\S]*?)(\n\n|$)/);
    if (!summarySections) return '';
    // Replace newlines within the section with spaces to format it as a single paragraph
    return summarySections[1].trim().replace(/\n/g, ' ');
};

const extractExperienceDescriptions = (text) => {
  const sections = text.split(/\n(?=[A-Z][a-z]+:)/);
  const experienceSection = sections.find(section => section.startsWith('Experience:'));
  
  if (!experienceSection) return '';
  
  return experienceSection.replace('Experience:', '').trim();
};

const extractInternshipDescriptions = (text) => {
  const sections = text.split(/\n(?=[A-Z][a-z]+:)/);
  const internshipSection = sections.find(section => section.startsWith('Internships:'));
  
  if (!internshipSection) return '';
  
  return internshipSection.replace('Internships:', '').trim();
};

const extractProjectDescriptions = (text) => {
  const sections = text.split(/\n(?=[A-Z][a-z]+:)/);
  const projectSection = sections.find(section => section.startsWith('Projects:'));
  
  if (!projectSection) return '';
  
  return projectSection.replace('Projects:', '').trim();
};


  const parseResponse = (text) => {
    const sections = text.split('\n\n'); // Assuming sections are separated by double new lines
    return sections.map((section, index) => {
      const firstNewLineIndex = section.indexOf('\n');
      const heading = section.substring(0, firstNewLineIndex).trim();
      const content = section.substring(firstNewLineIndex + 1).trim();
  
      // Check if the section should not be bolded
      const nonBoldPatterns = [/Email: .* Phone: .*/, /Overall, .*/];
      const isNonBold = nonBoldPatterns.some(pattern => pattern.test(heading));
  
      return (
        <div key={index}>
          <h2 className='text-left'>{heading}</h2>
          <p className='text-left'>{content}</p>
        </div>
      );
    });
  };

  

  return (
    <div className="flex justify-center items-center flex-col lg:flex-row p-6 gap-6 lg:gap-12 bg-[#f9f8fd]">

  <div className="1/2 sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-transparent  rounded-lg shadow-none">
    <form onSubmit={handleSubmit}>
      <div className='bg-white p-6 shadow-lg rounded-lg'>
      <h1 className="mb-5 text-2xl font-bold text-[#07074D]">Personal Details</h1>

      <div className="mb-5 flex flex-col gap-5 lg:flex-row lg:gap-5">
        <div className="flex-1">
          <label htmlFor="givenName" className="block mb-3 text-base text-left font-medium text-[#07074D]">
            First Name <span className='text-red-500'>*</span>
          </label>
          <input
            type="text"
            name="givenName"
            id="givenName"
            placeholder="First name"
            value={formData.givenName}
            onChange={handleChange}
            className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            
          />
          {formErrors.givenName && <span className="text-red-500">{formErrors.givenName}</span>}
        </div>
        <div className="flex-1">
          <label htmlFor="familyName" className="block mb-3 text-base text-left font-medium text-[#07074D]">
            Surname <span className='text-red-500'>*</span>
          </label>
          <input
            type="text"
            name="familyName"
            id="familyName"
            placeholder="Surname"
            value={formData.familyName}
            onChange={handleChange}
            className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          />
          {formErrors.familyName && <span className="text-red-500">{formErrors.familyName}</span>}
        </div>
      </div>


      <div className="mb-5">
        <label htmlFor="email" className="block mb-3 text-base text-left font-medium text-[#07074D]">
          Email address <span className='text-red-500'>*</span>
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email address"
          value={formData.email}
          onChange={handleChange}
          className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
        />
        {formErrors.email && <span className="text-red-500">{formErrors.email}</span>}
      </div>

      <div className="mb-5">
        <label htmlFor="headline" className="block mb-3 text-base text-left font-medium text-[#07074D]">
          Job Title <span className='text-red-500'>*</span>
        </label>
        <input
          type="text"
          name="headline"
          id="headline"
          placeholder="Job Title"
          value={formData.headline}
          onChange={handleChange}
          className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
        />
        {formErrors.headline && <span className="text-red-500">{formErrors.headline}</span>}
      </div>

      <div className="mb-5">
        <label htmlFor="phone" className="block mb-3 text-base text-left font-medium text-[#07074D]">
          Phone number <span className='text-red-500'>*</span>
        </label>
        <input
          type="text"
          name="phone"
          id="phone"
          placeholder="Phone number"
          value={formData.phone}
          onChange={handleChange}
          className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
        />
        {formErrors.phone && <span className="text-red-500">{formErrors.phone}</span>}
      </div>

      </div>
      <br/>
      <br/>

      <div className='bg-white p-6 shadow-lg rounded-lg'>
      <h1 className="mb-5 text-2xl font-bold text-[#07074D]">Professional Summary</h1>
      <div className="mb-5">
        <label htmlFor="summary" className="block mb-3 text-base text-left font-medium text-[#07074D]">
          Summary 
        </label>
        <textarea
          name="summary"
          id="summary"
          placeholder="Professional Summary"
          value={formData.summary}
          onChange={handleChange}
          className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          rows="4"
        />
      </div>
      </div>
      <br/>
      <br/>

     <div className='bg-white p-6 shadow-lg rounded-lg'>
      <h1 className="mb-5 text-2xl font-bold text-[#07074D]">Experiences</h1>
      {formData.experiences.map((experience, index) => (
        <div key={index} className="mb-5">
          <label className="block mb-3 text-base text-left font-medium text-[#07074D]">
            Company <span className='text-red-500'>*</span>
          </label>
          <input
            name='company'
            id='company'
            type="text"
            value={experience.company}
            onChange={(e) => handleArrayChange(index, 'company', e.target.value, 'experiences')}
            className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          />
          {formErrors.company && <span className="text-red-500">{formErrors.company}</span>}
          <label className="block mt-3 mb-3 text-base text-left font-medium text-[#07074D]">
            Role <span className='text-red-500'>*</span>
          </label>
          <input
            name='role'
            id='role'
            type="text"
            value={experience.role}
            onChange={(e) => handleArrayChange(index, 'role', e.target.value, 'experiences')}
            className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          />
          {formErrors.role && <span className="text-red-500">{formErrors.role}</span>}
          <label className="block mt-3 mb-3 text-base text-left font-medium text-[#07074D]">
            Start Date <span className='text-red-500'>*</span>
          </label>
          <input
            name='startDate'
            id='startDate'
            type="text"
            value={experience.startDate}
            onChange={(e) => handleArrayChange(index, 'startDate', e.target.value, 'experiences')}
            className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          />
          {formErrors.startDate && <span className="text-red-500">{formErrors.startDate}</span>}
          <label className="block mt-3 mb-3 text-base text-left font-medium text-[#07074D]">
            End Date <span className='text-red-500'>*</span>
          </label>
          <input
          name='endDate'
            id='endDate'
            type="text"
            value={experience.endDate}
            onChange={(e) => handleArrayChange(index, 'endDate', e.target.value, 'experiences')}
            className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          />
          {formErrors.endDate && <span className="text-red-500">{formErrors.endDate}</span>}
          <label className="block mt-3 mb-3 text-base text-left font-medium text-[#07074D]">
      Description <span className='text-red-500'>*</span>
    </label>
    <textarea
      name='description'
      id='description'
      value={experience.description}
      onChange={(e) => handleArrayChange(index, 'description', e.target.value, 'experiences')}
      className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
      rows="4"
    />
    {formErrors.description && <span className="text-red-500">{formErrors.description}</span>}
        </div>
      ))}
      <button type="button" onClick={() => handleAddEntry('experiences')} className="bg-blue-500 w-full text-white py-2 px-4 rounded-md mb-5">
        Add Experience 
      </button>
      </div>
      <br/>
      <br/>
      <br/>

      <div className='bg-white p-6 shadow-lg rounded-lg'>
            <h1 className="mb-5 text-2xl font-bold text-[#07074D]">Internships</h1>
            {formData.internships.map((internship, index) => (
              <div key={index} className="mb-5">
                <label className="block mb-3 text-base text-left font-medium text-[#07074D]">
                  Company <span className='text-red-500'>*</span>
                </label>
                <input
                  name='company'
                  id='internship-company'
                  type="text"
                  value={internship.company}
                  onChange={(e) => handleArrayChange(index, 'company', e.target.value, 'internships')}
                  className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
                {formErrors.internshipCompany && <span className="text-red-500">{formErrors.internshipCompany}</span>}

                <label className="block mt-3 mb-3 text-base text-left font-medium text-[#07074D]">
                  Role <span className='text-red-500'>*</span>
                </label>
                <input
                  name='role'
                  id='internship-role'
                  type="text"
                  value={internship.role}
                  onChange={(e) => handleArrayChange(index, 'role', e.target.value, 'internships')}
                  className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
                {formErrors.internshipRole && <span className="text-red-500">{formErrors.internshipRole}</span>}

                <label className="block mt-3 mb-3 text-base text-left font-medium text-[#07074D]">
                  Start Date <span className='text-red-500'>*</span>
                </label>
                <input
                  name='startDate'
                  id='internship-startDate'
                  type="text"
                  value={internship.startDate}
                  onChange={(e) => handleArrayChange(index, 'startDate', e.target.value, 'internships')}
                  className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
                {formErrors.internshipStartDate && <span className="text-red-500">{formErrors.internshipStartDate}</span>}

                <label className="block mt-3 mb-3 text-base text-left font-medium text-[#07074D]">
                  End Date <span className='text-red-500'>*</span>
                </label>
                <input
                  name='endDate'
                  id='internship-endDate'
                  type="text"
                  value={internship.endDate}
                  onChange={(e) => handleArrayChange(index, 'endDate', e.target.value, 'internships')}
                  className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
                {formErrors.internshipEndDate && <span className="text-red-500">{formErrors.internshipEndDate}</span>}

                <label className="block mt-3 mb-3 text-base text-left font-medium text-[#07074D]">
                  Description <span className='text-red-500'>*</span>
                </label>
                <textarea
                  name='description'
                  id='internship-description'
                  value={internship.description}
                  onChange={(e) => handleArrayChange(index, 'description', e.target.value, 'internships')}
                  className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  rows="4"
                />
                {formErrors.internshipDescription && <span className="text-red-500">{formErrors.internshipDescription}</span>}
              </div>
            ))}
            <button 
              type="button" 
              onClick={() => handleAddEntry('internships')} 
              className="bg-blue-500 w-full text-white py-2 px-4 rounded-md mb-5"
            >
              Add Internship
            </button>
          </div>
          <br/>
      <br/>
      <br/>

      <div className='bg-white p-6 shadow-lg rounded-lg'>
  <h1 className="mb-5 text-2xl font-bold text-[#07074D]">Projects</h1>
  {formData.projects.map((project, index) => (
    <div key={index} className="mb-5">
      <label className="block mb-3 text-base text-left font-medium text-[#07074D]">
        Project Title <span className='text-red-500'>*</span>
      </label>
      <input
        name='title'
        id='project-title'
        type="text"
        value={project.title}
        onChange={(e) => handleArrayChange(index, 'title', e.target.value, 'projects')}
        className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
      />
      {formErrors.projectTitle && <span className="text-red-500">{formErrors.projectTitle}</span>}

      <label className="block mt-3 mb-3 text-base text-left font-medium text-[#07074D]">
        Description <span className='text-red-500'>*</span>
      </label>
      <textarea
        name='description'
        id='project-description'
        value={project.description}
        onChange={(e) => handleArrayChange(index, 'description', e.target.value, 'projects')}
        className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
        rows="4"
      />
      {formErrors.projectDescription && <span className="text-red-500">{formErrors.projectDescription}</span>}

      <label className="block mt-3 mb-3 text-base text-left font-medium text-[#07074D]">
        GitHub Link <span className="text-gray-500 text-sm">(optional)</span>
      </label>
      <input
        name='githubLink'
        id='project-githubLink'
        type="url"
        value={project.githubLink}
        onChange={(e) => handleArrayChange(index, 'githubLink', e.target.value, 'projects')}
        className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
      />
    </div>
  ))}
  <button 
    type="button" 
    onClick={() => handleAddEntry('projects')} 
    className="bg-blue-500 w-full text-white py-2 px-4 rounded-md mb-5"
  >
    Add Project
  </button>
</div>
          <br/>
      <br/>
      <br/>

      <div className='bg-white p-6 shadow-lg rounded-lg'>
      <h1 className="mb-5 text-2xl font-bold text-[#07074D]">Education</h1>
      {formData.education.map((edu, index) => (
        <div key={index} className="mb-5">
          <label className="block mb-3 text-base text-left font-medium text-[#07074D]">
            Institution <span className='text-red-500'>*</span>
          </label>
          <input
            name='institution'
            id='institution'
            type="text"
            value={edu.institution}
            onChange={(e) => handleArrayChange(index, 'institution', e.target.value, 'education')}
            className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          />
          {formErrors.institution && <span className="text-red-500">{formErrors.institution}</span>}
          <label className="block mt-3 mb-3 text-base text-left font-medium text-[#07074D]">
            Degree <span className='text-red-500'>*</span>
          </label>
          <input
            name='degree'
            id='degree'
            type="text"
            value={edu.degree}
            onChange={(e) => handleArrayChange(index, 'degree', e.target.value, 'education')}
            className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          />
          {formErrors.degree && <span className="text-red-500">{formErrors.degree}</span>}
          <label className="block mt-3 mb-3 text-base text-left font-medium text-[#07074D]">
            Start Date <span className='text-red-500'>*</span>
          </label>
          <input
            name='startDate'
            id='startDate'
            type="text"
            value={edu.startDate}
            onChange={(e) => handleArrayChange(index, 'startDate', e.target.value, 'education')}
            className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          />
          {formErrors.startDate && <span className="text-red-500">{formErrors.startDate}</span>}
          <label className="block mt-3 mb-3 text-base text-left font-medium text-[#07074D]">
            End Date <span className='text-red-500'>*</span>
          </label>
          <input
            name='endDate'
            id='endDate'
            type="text"
            value={edu.endDate}
            onChange={(e) => handleArrayChange(index, 'endDate', e.target.value, 'education')}
            className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          />
          {formErrors.endDate && <span className="text-red-500">{formErrors.endDate}</span>}
        </div>
      ))}
      <button type="button" onClick={() => handleAddEntry('education')} className="bg-blue-500 w-full text-white py-2 px-4 rounded-md mb-5">
        Add Education 
      </button>
</div>
      <br/>
      <br/>
      <br/>
  

      <div className='bg-white p-6 shadow-lg rounded-lg'>
      <h1 className="mb-5 text-2xl font-bold text-[#07074D]">Skills</h1>
{formData.technologies.map((technology, index) => (
  <div key={index} className="mb-5">
    <label className="block mb-3 text-base text-left font-medium text-[#07074D]">
      Skill <span className='text-red-500'>*</span>
    </label>
    <input
      name='technologies'
      id='technologies'
      type="text"
      value={technology}
      onChange={(e) => handleTechnologyChange(index, e.target.value)}
      className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
    />
    {formErrors.technologies && <span className="text-red-500">{formErrors.technologies}</span>}
  </div>
))}
<button type="button" onClick={handleAddTechnology} className="bg-blue-500 w-full text-white py-2 px-4 rounded-md mb-5">
  Add Skill
</button>
</div>
      <br/>
      <br/>
      <br/>

      <div className='bg-white p-6 shadow-lg rounded-lg'>
<h1 className="mb-5 text-2xl font-bold text-[#07074D]">Languages</h1>
{formData.languages.map((language, index) => (
  <div key={index} className="mb-5">
    <label className="block mb-3 text-base text-left font-medium text-[#07074D]">
      Language <span className='text-red-500'>*</span>
    </label>
    <input
      name='languages'
      id='languages'
      type="text"
      value={language}
      onChange={(e) => handleLanguageChange(index, e.target.value)}
      className="w-full rounded-md border border-[#e0e0e0] bg-[#eaf5ff] py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
    />
    {formErrors.languages && <span className="text-red-500">{formErrors.languages}</span>}
  </div>
))}
<button type="button" onClick={handleAddLanguage} className="bg-blue-500 w-full text-white py-2 px-4 rounded-md mb-5">
  Add Language
</button>
</div>
<br/>
      <br/>
      <br/>

  
      <button onClick={() => {
    const errors = validate(); // Run the validation function
    if (Object.keys(errors).length === 0) {
      // If there are no errors, call handleGenerateResume
      handleGenerateResume();
    } else {
      // Optionally, handle the display of errors here
      console.log('Validation failed:', errors);
    }
  }}
        type="submit"
        className="bg-blue-500 w-full text-white py-2 px-4 rounded-md"
      >
        Generate Resume
      </button>

      {isLoading && <LoadingPage />}
      <br/>
      <br/>
      <br/>
    </form>
  </div>

  
  {/* <div className="w-full lg:w-[700px] bg-gray-100 p-6 rounded-lg shadow-lg">
    <h2 className="text-lg font-bold text-[#07074D] mb-4">Response</h2>
    {parseResponse(response)}
  </div> */}
</div>



  );
};

export default FormComponent;
