import React from 'react';
// import '../Template9/Resume9.css';
import { useResumeData } from '../Context/ResumeContext';

const Resume9 = () => {
  const { resumeData } = useResumeData();
  const {
    givenName = 'Anusha',
    familyName = 'C',
    email = 'call6anushac@gmail.com',
    phone = '7022252583',
    address = 'India',
    headline = 'Aspiring DevOps Professional',
    experiences = [],
    internships = [],
    projects = [],
    education = [],
    skills = [],
    technologies = [],
    languages = [],
    certifications = [],
    professionalSummary = '',
    experienceDescription ='',
    internshipDescription ='',
    projectDescription ='',
    photo = null,
  } = resumeData || {};

  const handlePrint = () => {
    setTimeout(() => {
      window.print();
    }, 300);
  };

  return (
    <div>
      
      <div className="rb-resume-container9 text-left">
        <header className="rb-header9 ">
        <div className="flex justify-between">
  <div className="rb-header-text">
    <h1>{`${givenName} ${familyName}`}</h1>
    <p>{headline}</p>
  </div>
  {photo && (
    <img
      src={URL.createObjectURL(photo)}
      alt={`${givenName} ${familyName}`}
      className="rb-profile-image9"
    />
  )}
</div>

           <section className="rb-contact-section9">
              <p className="rb-text-sm"><i className="fa-regular fa-envelope"></i> {email}</p>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <p className="rb-text-sm"><i className="fa-solid fa-phone"></i> {phone}</p>
              {/* <p className="text-sm"><i className="fa-solid fa-location-dot"></i> {address}</p> */}
            </section>
        </header>
        <div className="rb-content-wrapper9">
          <main className="rb-main-content9">
            <section className="rb-experience-section9">
              <h2>Professional Summary</h2>
              <p>{professionalSummary}</p>
            </section>
            <br/>
            <section>
            <div className="rb-about-me-section9">
            <h2>Work Experience</h2>
            <div className="text-left">
              {experiences.map((exp, index) => {
                const paragraphs = experienceDescription.split('\n\n');
                const currentParagraph = paragraphs[index] || '';
                const sentences = currentParagraph.split('. ');

                return (
                  <div key={index}>
            <p className='font-semibold text-lg text-[#000000] color4'>{exp.company}</p>
            <p className='text-[#3D3D3D] text-md color1'>{exp.role}</p>
            <p className='text-[#7C7C7C] text-sm color2'>{`${exp.startDate} - ${exp.endDate}`}</p>
            {sentences.map((sentence, sentenceIndex) => (
              <p key={sentenceIndex}>
                {sentenceIndex < sentences.length  ? '•\u00A0' : ''}{sentence.trim()}
                {sentenceIndex < sentences.length - 1 ? '.' : ''}
              </p>
            ))}
            <br/>
          </div>
        
          );
        })}
      </div>
    </div>

    {/* New Internships Section */}
    <section>
              <div className="rb-about-me-section9">
                <h2>Internships</h2>
                <div className="text-left">
                  {internships.map((internship, index) => {
                    const paragraphs = internshipDescription.split('\n\n');
                    const currentParagraph = paragraphs[index] || '';
                    const sentences = currentParagraph.split('. ');

                    return (
                      <div key={index}>
                        <p className='font-semibold text-lg text-[#000000] color4'>{internship.company}</p>
                        <p className='text-[#3D3D3D] text-md color1'>{internship.role}</p>
                        <p className='text-[#7C7C7C] text-sm color2'>{`${internship.startDate} - ${internship.endDate}`}</p>
                        {sentences.map((sentence, sentenceIndex) => (
                          <p key={sentenceIndex}>
                            {sentenceIndex < sentences.length ? '•\u00A0' : ''}{sentence.trim()}
                            {sentenceIndex < sentences.length - 1 ? '.' : ''}
                          </p>
                        ))}
                        <br/>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>

            <section>
              <div className="rb-about-me-section9">
                <h2>Projects</h2>
                <div className="text-left">
                  {projects.map((project, index) => {
                    const paragraphs = projectDescription.split('\n\n');
                    const currentParagraph = paragraphs[index] || '';
                    const sentences = currentParagraph.split('. ');

                    return (
                      <div key={index}>
                        <p className='font-semibold text-lg text-[#000000] color4'>{project.title}</p>
                        {sentences.map((sentence, sentenceIndex) => (
                          <p key={sentenceIndex}>
                            {sentenceIndex < sentences.length ? '•\u00A0' : ''}{sentence.trim()}
                            {sentenceIndex < sentences.length - 1 ? '.' : ''}
                          </p>
                        ))}
                        <br/>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>

            </section>
            <section className="rb-education-section9">
              <h2>Education</h2>
              {education.map((edu, index) => (
                <div key={index}>
                  <h3 className="font-semibold text-lg">{edu.degree}</h3>
                  <p className="text-[#3D3D3D] text-md">{edu.institution}</p>
                  <p className="text-[#7C7C7C] text-sm">{`${edu.startDate} - ${edu.endDate}`}</p>
                </div>
              ))}
            </section>
          </main>
          <aside className="rb-sidebar9">
          <br/>

            <br/>
            <section className="rb-skills-section9">
            <h2>Skills</h2>
            <div className="flex flex-wrap gap-2">
              {technologies.map((tech, index) => (
                <span key={index}>
                  {tech}{index < technologies.length - 1 ? ", " : ""}
                </span>
              ))}
            </div>
          </section>
            <br/>
            <section className="rb-language-section9">
              <h2>Languages</h2>
              <div className="flex flex-wrap gap-2">
                {languages.map((language, index) => (
                  <span key={index}>
                    {language}{index < languages.length - 1 ? ", " : ""}
                  </span>
                ))}
              </div>
            </section>
          </aside>
        </div>
      </div>
      <div className="rb-resume-containerX text-center p-10">
        <button onClick={handlePrint} className="bg-blue-500 text-white px-4 py-2 rounded-md mr-4">
          Print
        </button>
      </div>
   
    </div>
  );
};

export default Resume9;
