import React from 'react';
// import '../Template10/Resume10.css';
import { useResumeData } from '../Context/ResumeContext';
import { useEffect, useState } from 'react';

const Resume10 = () => {
  const { resumeData } = useResumeData();
  const {
    givenName = 'Anusha',
    familyName = 'C',
    email = 'call6anushac@gmail.com',
    phone = '7022252583',
    address = 'India',
    headline = 'Aspiring DevOps Professional',
    experiences = [],
    internships = [],
    projects = [],
    education = [],
    skills = [],
    technologies = [],
    languages = [],
    certifications = [],
    professionalSummary = '',
    experienceDescription ='',
    internshipDescription ='',
    projectDescription ='',
    photo = null,
  } = resumeData || {};


  const handlePrint = () => {
    setTimeout(() => {
      window.print();
    }, 300);
  };

  return (
    <div className='mt-5'>
      <div className="rb-resume-container10 ">
        <header className="rb-header10 ">
          <div className="flex justify-center">
            <div className="rb-header-text text-center">
              <h1>{`${givenName} ${familyName}`}</h1>
              <p>{headline}</p>
            </div>
          </div>

          <section className="rb-contact-section10 justify-center">
            <p className="text-sm"><i className="fa-regular fa-envelope"></i> {email}</p>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <p className="text-sm"><i className="fa-solid fa-phone"></i> {phone}</p>
            {/* <p className="text-sm"><i className="fa-solid fa-location-dot"></i> {address}</p> */}
          </section>
        </header>
        <div className="rb-content-wrapper10">
          <main className="rb-main-content10">
            <section className="rb-experience-section10 ">
              <h2 className='font-semibold '>Professional Summary</h2>
              <p className='text-left'>{professionalSummary}</p>
            </section>
            <br />
            <section>
              <div className="rb-about-me-section ">
                <h2 className='font-semibold'>Work Experience</h2>
                <div className="text-left">
                  {experiences.map((exp, index) => {
                    const paragraphs = experienceDescription.split('\n\n');
                    const currentParagraph = paragraphs[index] || '';
                    const sentences = currentParagraph.split('. ');

                    return (
                      <div key={index}>
                        <p className='font-semibold text-lg text-[#000000] color4'>{exp.company}</p>
                        <p className='text-[#3D3D3D] text-md color1'>{exp.role}</p>
                        <p className='text-[#7C7C7C] text-sm color2'>{`${exp.startDate} - ${exp.endDate}`}</p>
                        {sentences.map((sentence, sentenceIndex) => (
                          <p key={sentenceIndex}>
                            {sentenceIndex < sentences.length ? '•\u00A0' : ''}{sentence.trim()}
                            {sentenceIndex < sentences.length - 1 ? '.' : ''}
                          </p>
                        ))}
                        <br />
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* New Internships Section */}
              <section>
                <div className="rb-about-me-section">
                  <h2 className='font-semibold'>Internships</h2>
                  <div className="text-left">
                    {internships.map((internship, index) => {
                      const paragraphs = internshipDescription.split('\n\n');
                      const currentParagraph = paragraphs[index] || '';
                      const sentences = currentParagraph.split('. ');

                      return (
                        <div key={index}>
                          <p className='font-semibold text-lg text-[#000000] color4'>{internship.company}</p>
                          <p className='text-[#3D3D3D] text-md color1'>{internship.role}</p>
                          <p className='text-[#7C7C7C] text-sm color2'>{`${internship.startDate} - ${internship.endDate}`}</p>
                          {sentences.map((sentence, sentenceIndex) => (
                            <p key={sentenceIndex}>
                              {sentenceIndex < sentences.length ? '•\u00A0' : ''}{sentence.trim()}
                              {sentenceIndex < sentences.length - 1 ? '.' : ''}
                            </p>
                          ))}
                          <br />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <section>
                <div className="rb-about-me-section">
                  <h2 className='font-semibold'>Projects</h2>
                  <div className="text-left">
                    {projects.map((project, index) => {
                      const paragraphs = projectDescription.split('\n\n');
                      const currentParagraph = paragraphs[index] || '';
                      const sentences = currentParagraph.split('. ');

                      return (
                        <div key={index}>
                          <p className='font-semibold text-lg text-[#000000] color4'>{project.title}</p>
                          {sentences.map((sentence, sentenceIndex) => (
                            <p key={sentenceIndex}>
                              {sentenceIndex < sentences.length ? '•\u00A0' : ''}{sentence.trim()}
                              {sentenceIndex < sentences.length - 1 ? '.' : ''}
                            </p>
                          ))}
                          <br />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

            </section>
            <section className="rb-education-section9">
              <h2 className='font-semibold'>Education</h2>
              {education.map((edu, index) => (
                <div className='text-left' key={index}>
                  <h3 className="font-semibold text-lg">{edu.degree}</h3>
                  <p className="text-[#3D3D3D] text-md">{edu.institution}</p>
                  <p className="text-[#7C7C7C] text-sm">{`${edu.startDate} - ${edu.endDate}`}</p>
                  <br />
                </div>
              ))}
            </section>
          </main>
          <aside className="rb-sidebar10">
            <br />

            <section className="rb-skills-section10">
              <h2 className='font-semibold'>Skills</h2>
              <div className="flex flex-wrap gap-2 text-left">
                {technologies.map((tech, index) => (
                  <span key={index}>
                    {tech}{index < technologies.length - 1 ? ", " : ""}
                  </span>
                ))}
              </div>
            </section>
            <br />
            <section className="rb-language-section10">
              <h2 className='font-semibold'>Languages</h2>
              <div className="flex flex-wrap gap-2">
                {languages.map((language, index) => (
                  <span key={index}>
                    {language}{index < languages.length - 1 ? ", " : ""}
                  </span>
                ))}
              </div>
            </section>
          </aside>
        </div>
      </div>
      <div className="rb-resume-containerX text-center p-10">
        <button onClick={handlePrint} className="bg-blue-500 text-white px-4 py-2 rounded-md mr-4">
          Print
        </button>
      </div>
    </div>
  );
};

export default Resume10;
