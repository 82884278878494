import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Hero = () => {

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const templates = [
    {
      id: 1,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-07%20172137_yHjgzrQkN.png?updatedAt=1723091002110',
      altText: 'Template1',
      link: '/create-resume',
    },
    {
      id: 2,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-07%20172226_wgnBt9SR2.png?updatedAt=1723091001231',
      altText: 'Template2',
      link: '/create-resume',
    },
    {
      id: 3,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-07%20172303_e6ZhjmdWg.png?updatedAt=1723091001197',
      altText: 'Template3',
      link: '/create-resume',
    },
    {
      id: 4,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-19%20111125_dXf2kpuAy.png?updatedAt=1724059007226',
      altText: 'Template4',
      link: '/create-resume',
    },
    {
      id: 5,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-19%20144231_QpG3zJ7GEo.png?updatedAt=1724059007193',
      altText: 'Template5',
      link: '/create-resume',
    },
    {
      id: 6,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-19%20144257_-7VllQwpWk.png?updatedAt=1724059026752',
      altText: 'Template6',
      link: '/create-resume',
    },
    {
      id: 7,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-19%20144335_Vi7eKmmINe.png?updatedAt=1724059026630',
      altText: 'Template7',
      link: '/create-resume',
    },
    {
      id: 8,
      imgSrc: 'https://ik.imagekit.io/microdegree/Screenshot%202024-08-19%20144352_H99TlfP23.png?updatedAt=1724059007189',
      altText: 'Template8',
      link: '/create-resume',
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (

    <div className='bg-[#f9f8fd] overflow-hidden'>
  <div class="max-w-[85rem] flex items-center justify-center mx-auto px-4 sm:px-6 lg:px-8 pt-10 overflow-hidden">
  <div class="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center">
    <div>
         {/* <!-- Logo Section --> */}
  <div class="flex items-center mb-4">
    <p class="text-4xl">
      <span class="text-[#6339d5] font-medium">Micro</span>
      <span class="text-[#FF0049] font-bold">Resume</span>
    </p>
  </div>
    <h1 class="block text-3xl font-bold text-[#4159ec] sm:text-4xl lg:text-[45px] lg:leading-tight text-left">
      ನಿಮ್ಮ Dream Job Awaits with an AI-Crafted Resume
      </h1>
      <p class="mt-3 text-lg text-gray-800 text-left">
        Our advanced tool leverages AI to generate a polished resume in no time. Simply input your details, choose from expertly designed templates, and let our AI enhance and format your resume to highlight your skills and experience.
      </p>

      <div class="mt-7 grid gap-3 w-full sm:inline-flex">
        <Link
          to="/choose-template"
          class="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-lg font-medium rounded-lg border border-transparent bg-[#FF0049] text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
        >
          Create My Resume
          <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="m9 18 6-6-6-6"/>
          </svg>
        </Link>
      </div>
    </div>

    <div class="relative">
      <img class="w-full rounded-md" src="https://ik.imagekit.io/microdegree/Resume%20Builder/home-logo_dpAX3MBx_.webp?updatedAt=1724309515356" alt="Hero Image"/>

    </div>
  </div>
</div>
<br/>
<br/>
<section class="relative overflow-hidden bg-gray-50 ">
    <div class="mt-2 md:mt-0 py-12 pb-6 sm:py-16 lg:pb-24 overflow-hidden">
    <h1 class="text-4xl text-[#001ec6] font-bold mb-12">Create Your Perfect Resume in 3 Easy Steps</h1>
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 relative">
            <div class="relative mt-12 lg:mt-20">
                <div class="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
                    <svg class="w-full" xmlns="http://www.w3.org/2000/svg" width="875" height="48" viewBox="0 0 875 48"
                        fill="none">
                        <path
                            d="M2 29C20.2154 33.6961 38.9915 35.1324 57.6111 37.5555C80.2065 40.496 102.791 43.3231 125.556 44.5555C163.184 46.5927 201.26 45 238.944 45C312.75 45 385.368 30.7371 458.278 20.6666C495.231 15.5627 532.399 11.6429 569.278 6.11109C589.515 3.07551 609.767 2.09927 630.222 1.99998C655.606 1.87676 681.208 1.11809 706.556 2.44442C739.552 4.17096 772.539 6.75565 805.222 11.5C828 14.8064 850.34 20.2233 873 24"
                            stroke="#001ec6" stroke-width="3" stroke-linecap="round" stroke-dasharray="1 12" />
                    </svg>
                </div>
                <div
                    class="relative grid grid-cols-1 text-center gap-y-8 sm:gap-y-10 md:gap-y-12 md:grid-cols-3 gap-x-12">
                    <div>
                        <div
                            class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200  rounded-full shadow">
                            <span class="text-xl font-semibold text-gray-700">1</span>
                        </div>
                        <div
                class="relative p-4 my-6 text-gray-800 bg-white rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                 <div class="flex items-center justify-center">
                      <img className='h-20' src="https://ik.imagekit.io/microdegree/Resume%20Builder/selective-unscreen_pSQI1OfNd.gif?updatedAt=1725347288638"/>
                </div>
                <h3 class="text-lg font-semibold lg:text-2xl">Choose Your Template</h3>
                <p class="mt-2 leading-6 text-md">Start by selecting from a range of professionally designed templates that best suits your style and career goals</p>
               
               </div>
                    </div>
                    <div>
                        <div
                            class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                            <span class="text-xl font-semibold text-gray-700">2</span>
                        </div>
                        <div class="relative p-4 my-6 text-gray-800 bg-white rounded-xl col-start-6 col-end-10 mr-auto">
                     <div class="flex items-center justify-center">
                      <img className='h-20' src="https://ik.imagekit.io/microdegree/Resume%20Builder/registration-unscreen_AQrG-iHgl.gif?updatedAt=1725347288553"/>
                </div>
                <h3 class="text-lg font-semibold lg:text-2xl">Fill in Your Details</h3>
                <p class="mt-2 leading-6 text-md">Complete the user-friendly form with your personal information, work experience, education, and skills</p>
                
                </div>
                    </div>
                    <div>
                        <div
                            class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                            <span class="text-xl font-semibold text-gray-700">3</span>
                        </div>
                        <div
                class="relative p-4 my-6 text-gray-800 bg-white rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                  <div class="flex items-center justify-center">
                      <img className='h-20' src="https://ik.imagekit.io/microdegree/Resume%20Builder/download-file-unscreen_YZYeoMADR.gif?updatedAt=1725347288427"/>
                </div>
                <h3 class="text-lg font-semibold lg:text-2xl">Generate and Download</h3>
                <p class="mt-2 leading-6 text-md">Our AI instantly creates your resume—just download and you're ready to impress</p>
                
                </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-7 flex justify-center w-full sm:inline-flex">
  <Link
    to="/choose-template"
    class="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-lg font-medium rounded-lg border border-transparent bg-[#FF0049] text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
  >
    Get Started Now
    <svg
      class="shrink-0 size-4"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  </Link>
</div>

    </div>
</section>



{/* <div class="bg-none p-4 mt-5 2xl:px-60 xl:px-40">
    <h2 class="text-4xl text-black font-bold mb-12">Create Your Perfect Resume in 3 Easy Steps</h2>
    <div class="flex flex-col grid-cols-9 p-2 mx-auto md:grid">
        <div class="flex md:contents flex-row-reverse">
            <div
                class="relative p-4 my-6 text-gray-800 bg-white rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                 <div class="flex items-center justify-center">
                      <img className='h-20' src="https://ik.imagekit.io/microdegree/Resume%20Builder/selective-unscreen_pSQI1OfNd.gif?updatedAt=1725347288638"/>
                </div>
                <h3 class="text-lg font-semibold lg:text-2xl">Choose Your Template</h3>
                <p class="mt-2 leading-6 text-md">Start by selecting from a range of professionally designed templates that best suits your style and career goals</p>
                <span class="absolute text-lg text-[#4159ec] -top-8 left-2 whitespace-nowrap">Step 1</span>
            </div>
            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto">
                <div class="flex items-center justify-center w-6 h-full">
                    <div class="w-1 h-full bg-indigo-300 rounded-t-full bg-gradient-to-b from-indigo-400 to-indigo-300">
                    </div>
                </div>
                <div class="absolute w-6 h-6 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2"></div>
            </div>
        </div>

        <div class="flex md:contents">
            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto">
                <div class="flex items-center justify-center w-6 h-full">
                    <div class="w-1 h-full bg-indigo-300"></div>
                </div>
                <div class="absolute w-6 h-6 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2"></div>
            </div>
            <div class="relative p-4 my-6 text-gray-800 bg-white rounded-xl col-start-6 col-end-10 mr-auto">
            <div class="flex items-center justify-center">
                      <img className='h-20' src="https://ik.imagekit.io/microdegree/Resume%20Builder/registration-unscreen_AQrG-iHgl.gif?updatedAt=1725347288553"/>
                </div>
                <h3 class="text-lg font-semibold lg:text-2xl">Fill in Your Details</h3>
                <p class="mt-2 leading-6 text-md">Complete the user-friendly form with your personal information, work experience, education, and skills</p>
                <span class="absolute text-lg text-[#4159ec] -top-8 left-2 whitespace-nowrap">Step 2</span>
            </div>
        </div>

        <div class="flex md:contents flex-row-reverse ">
            <div
                class="relative p-4 my-6 text-gray-800 bg-white rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                  <div class="flex items-center justify-center">
                      <img className='h-20' src="https://ik.imagekit.io/microdegree/Resume%20Builder/download-file-unscreen_YZYeoMADR.gif?updatedAt=1725347288427"/>
                </div>
                <h3 class="text-lg font-semibold lg:text-2xl">Generate and Download</h3>
                <p class="mt-2 leading-6 text-md">Our AI instantly creates your resume—just download and you're ready to impress</p>
                <span class="absolute text-lg text-[#4159ec] -top-8 left-2 whitespace-nowrap">Step 3</span>
            </div>
            <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto">
                <div class="flex items-center justify-center w-6 h-full">
                    <div class="w-1 h-full bg-indigo-300 rounded-t-full bg-gradient-to-b from-indigo-400 to-indigo-300">
                    </div>
                </div>
                <div class="absolute w-6 h-6 -mt-3 bg-white border-4 border-indigo-400 rounded-full top-1/2"></div>
            </div>
        </div>
    </div>
</div> */}

<div className="relative px-4 pt-10 pb-10 bg-[#f9f8fd] overflow-hidden">
<h1 class="text-4xl text-[#001ec6] font-bold mb-12">Pick a template and build your resume in minutes</h1>
<br/>
            <Slider {...sliderSettings}>
              {templates.map((template) => (
                <div key={template.id} className="px-1">
                  <img 
                    src={template.imgSrc} 
                    alt={template.altText} 
                    className="w-full h-auto rounded-md shadow-lg"
                    style={{ maxWidth: '300px', margin: '0 auto' }} // Adjust the maxWidth as needed
                  />
                </div>
              ))}
            </Slider>
          </div>

          <br/>
          <br/>

          <div class="px-2 py-10 bg-[#f9f8fd]">

<div id="features" class="mx-auto max-w-6xl overflow-hidden">
  <h1 class="text-center font-display text-3xl font-bold tracking-tight text-[#001ec6] md:text-4xl">
  Why Choose Our AI-Driven Resume Builder
  </h1>
  <ul class="mt-16 grid grid-cols-1 gap-6 px-5 text-center text-slate-700 md:grid-cols-3">
    <li class="rounded-xl bg-white px-6 py-8 shadow-sm">

      <img src="https://www.svgrepo.com/show/530438/ddos-protection.svg" alt="" class="mx-auto h-10 w-10"/>
      <h3 class="my-3 font-display font-medium">Template Selection</h3>
      <p class="mt-1.5 text-sm leading-6 text-secondary-500">
       Users can choose from a variety of professionally designed templates tailored to different job roles and industries.
      </p>

    </li>
    <li class="rounded-xl bg-white px-6 py-8 shadow-sm">

      <img src="https://www.svgrepo.com/show/530442/port-detection.svg"
              alt="" class="mx-auto h-10 w-10"/>
      <h3 class="my-3 font-display font-medium">Easy Form Filling</h3>
      <p class="mt-1.5 text-sm leading-6 text-secondary-500">
      Users input personal, educational, and professional details using a simple, guided form interface.
      </p>

    </li>
    <li class="rounded-xl bg-white px-6 py-8 shadow-sm">
      <img src="https://www.svgrepo.com/show/530444/availability.svg" alt="" class="mx-auto h-10 w-10"/>
      <h3 class="my-3 font-display font-medium">AI-Powered Resume Generation</h3>
      <p class="mt-1.5 text-sm leading-6 text-secondary-500">
      Once details are filled, the AI processes the data to create a polished and structured resume automatically.
      </p>
    </li>
    <li class="rounded-xl bg-white px-6 py-8 shadow-sm">
      <a class="group">
        <img src="https://www.svgrepo.com/show/530440/machine-vision.svg" alt="" class="mx-auto h-10 w-10"/>
        <h3 class="my-3 font-display font-medium group-hover:text-primary-500">Downloadable Resume </h3>
        <p class="mt-1.5 text-sm leading-6 text-secondary-500"> Users can download the final AI-generated resume in PDF or other popular formats.</p>
      </a>
    </li>
    <li class="rounded-xl bg-white px-6 py-8 shadow-sm">
      <a class="group">
        <img src="https://www.svgrepo.com/show/530450/page-analysis.svg" alt="" class="mx-auto h-10 w-10"/>
        <h3 class="my-3 font-display font-medium group-hover:text-primary-500">
        Auto Formatting
        </h3>
        <p class="mt-1.5 text-sm leading-6 text-secondary-500">The AI automatically formats the content to ensure consistency in fonts, alignment, and overall layout.</p>
      </a>
    </li>
    <li class="rounded-xl bg-white px-6 py-8 shadow-sm">
      <a class="group">
        <img src="https://www.svgrepo.com/show/530453/mail-reception.svg" alt="" class="mx-auto h-10 w-10"/>
        <h3 class="my-3 font-display font-medium group-hover:text-primary-500">Mobile-Friendly Design</h3>
        <p class="mt-1.5 text-sm leading-6 text-secondary-500">The tool is fully optimized for mobile devices, allowing users to create resumes on the go with ease</p>
      </a>
    </li>
  </ul>
</div>

<div class="mt-7 flex justify-center w-full sm:inline-flex">
  <Link
    to="/choose-template"
    onClick={(e) => {
      e.preventDefault();
      handleNavigation('/choose-template');
    }}
    class="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-lg font-medium rounded-lg border border-transparent bg-[#FF0049] text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
  >
    Build my Resume
    <svg
      class="shrink-0 size-4"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  </Link>
</div>

</div>

    </div>
  )
}

export default Hero
